<template>
  <v-app-bar flat color="white" style="z-index: 10" app>
    <v-app-bar-nav-icon
      @click.stop="$emit('drawer-left-click')"
      color="#4d4c4c"
    ></v-app-bar-nav-icon>

    <router-link to="/">
      <div class="d-flex align-center logo">
        <img alt="FRISS Logo" src="@/assets/FRISS-BW-Logo.svg" height="40px" />
      </div>
    </router-link>
    <span class="pl-3">Claims</span>

    <v-spacer></v-spacer>

    <v-text-field
      flat
      background-color="#F1F3F4"
      style="max-width: 450px"
      class="my-10 mx-4 rounded-lg search-input"
      solo
      hide-details
      dense
      color="blue"
      placeholder="Type to search"
      append-icon="mdi-magnify"
      clearable
      @keyup.enter="overlay = true"
      @click:append="overlay = true"
    ></v-text-field>

    <v-spacer></v-spacer>

    <!-- <v-btn icon @click.stop="$emit('drawer-top-click')">
      <v-icon>mdi-bell-outline</v-icon>
    </v-btn> -->

    <v-btn icon>
      <v-icon
        @click.stop="$emit('drawer-right-click')"
        color="#4d4c4c"
        class="tune-icon"
        >mdi-tune</v-icon
      >
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style lang="scss">
.search-input .v-icon {
  color: #4d4c4c;
}

.tune-icon {
  transform: rotate(90deg);
}
</style>
