<template>
  <v-row class="mt-4">
    <v-col cols="2">
      <ImageCarousel :images="data" @slideClicked="onSlideClick" />
    </v-col>
    <v-col cols="10">
      <ImageDetails
        :images="formattedImages"
        :image="currentImage"
        :currentIndex="currentIndex"
      />
    </v-col>
  </v-row>
</template>

<script>
import ImageDetails from "@/components2/photos/ImageDetails.vue";
import ImageCarousel from "@/components2/photos/ImageCarousel";

export default {
  components: {
    ImageCarousel,
    ImageDetails
  },

  props: {
    data: {
      type: Array
    }
  },

  mounted() {
    this.currentImage = this.data?.[0];
  },

  data() {
    return {
      currentImage: null,
      currentIndex: 0
    };
  },

  methods: {
    onSlideClick(value) {
      this.currentIndex = value;
      this.currentImage = this.data?.[this.currentIndex];
    }
  },

  computed: {
    formattedImages() {
      return this.data.map((image) => {
        return {
          src: image.path,
          title: image.providedBy,
          description: image.providerType
        };
      });
    }
  }
};
</script>
