<template>
  <div class="diagnostics">
    <v-tabs height="38" v-model="selection" background-color="transparent">
      <v-tabs-slider color="#1A8DFF"></v-tabs-slider>
      <v-tab v-for="(item, index) in items" :key="index" class="pl-2">
        <span style="color: #8f8f8f; font-size: 14px">
          <span class="ml-1 text-capitalize">{{ item.title }}</span>
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selection">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <component :is="item.component" :headers="headers" :items="tableData"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Table from "@/components3/Table";

export default {
  data() {
    return {
      selection: 0,
      headers: [
        {
          text: "Interface Id",
          value: "interface_id"
        },
        { text: "Interface Name", value: "interface_name" },
        { text: "Date", value: "date" },
        { text: "Result", value: "result" },
        { text: "Response Time", value: "response_time" },
        { text: "", value: "download" }
      ],
      tableData: [
        {
          interface_id: 1291,
          interface_name: "FrissClaimRegistration",
          date: "07/28/2021 11:23 AM",
          result: "ERROR",
          response_time: 551,
          download: true
        },
        {
          interface_id: 1311,
          interface_name: "Update Previous Alert",
          date: "07/28/2021 11:23 AM",
          result: "OK",
          response_time: 15,
          download: true
        },
        {
          interface_id: 336,
          interface_name: "Signalering",
          date: "07/28/2021 11:23 AM",
          result: "OK",
          response_time: 0
        }
      ],
      items: [
        { title: "Log Interface", component: Table },
        { title: "Log Interface XML", component: null },
        { title: "Log Viariables" },
        { title: "FDE Logs" },
        { title: "Datasource Response" },
        { title: "FRISS API Request" }
      ]
    };
  }
};
</script>

<style lang="scss">
.diagnostics {
  .v-tabs {
    .v-tabs-bar {
      border-top: 0 !important;
      border-bottom: 0 !important;
    }
  }
}
</style>
