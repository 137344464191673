<template>
  <v-row class="mt-5">
    <v-col cols="12">
      <v-list class="pb-9 vehicle-passengers">
        <div
          class="
            px-1
            py-3
            mx-4
            font-16 font-color-dark font-weight-bold
            passengers-title
          "
        >
          In this vehicle
        </div>
        <v-list-item-group color="primary" class="px-4">
          <v-list-item
            v-for="(passenger, index) in passengers"
            :key="index"
            class="passenger-item"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                <div
                  class="
                    passenger-name
                    font-16 font-color-dark font-weight-bold
                  "
                >
                  {{ passenger.fullName }}
                </div>
                <div
                  class="
                    text-left
                    passenger-description
                    font-12 font-color-light
                  "
                >
                  {{ passenger.description }}
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["passengers"]
};
</script>

<style>
.vehicle-passengers {
  border-radius: 6px;
}
.passenger-item,
.passengers-title {
  border-bottom: 1px solid #e9e9e9;
}
.passenger-name {
  min-width: 280px;
}
.passenger-description {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
