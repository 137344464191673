<template>
  <v-row class="mt-4">
    <v-col cols="3">
      <VehiclesSide :vehicles="data" @selectedVehicle="setSelectedVehicle" />
    </v-col>
    <v-col cols="9">
      <v-card
        class="mb-4 elevation-0 vehicle-card"
        v-for="(vehicle, index) in data"
        :key="index"
        v-show="selectedVehicle === index"
        id="#observer-root2"
      >
        <CardWithFooter colorTop="white" colorBottom="#BADDFF">
          <template #top>
            <VehicleDetailTop :vehicle="vehicle" :index="index" />
          </template>
          <template #bottom>
            <VehicleDetailBottom :vehicle="vehicle" />
          </template>
        </CardWithFooter>

        <VehiclePassengers :passengers="vehicle.passengers" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CardWithFooter from "../CardWithFooter.vue";
import VehicleDetailTop from "../vehicles/VehicleDetailTop.vue";
import VehicleDetailBottom from "../vehicles/VehicleDetailBottom.vue";
import VehiclesSide from "../vehicles/VehiclesSide.vue";
import VehiclePassengers from "../vehicles/VehiclePassengers.vue";

export default {
  components: {
    CardWithFooter,
    VehicleDetailTop,
    VehicleDetailBottom,
    VehiclesSide,
    VehiclePassengers
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      selectedVehicle: 0
    };
  },

  methods: {
    setSelectedVehicle(val) {
      this.selectedVehicle = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.vehicle-card {
  background-color: transparent;
}
</style>
