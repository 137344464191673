<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7539 0.0702875C11.6758 0.0234289 11.5918 0 11.502 0C11.4121 0 11.3281 0.0234289 11.25 0.0702875L8.00391 1.96805L4.19531 0.0702875C4.19531 0.0702875 4.19336 0.0702875 4.18945 0.0702875C4.18555 0.0702875 4.17188 0.0702875 4.14844 0.0702875C4.125 0.0468582 4.10156 0.0331913 4.07812 0.0292864C4.05469 0.0253816 4.02734 0.0234292 3.99609 0.0234292C3.97266 0.0234292 3.94727 0.0253816 3.91992 0.0292864C3.89258 0.0331913 3.85156 0.0468582 3.79688 0.0702875C3.79688 0.0702875 3.79492 0.0702875 3.79102 0.0702875C3.78711 0.0702875 3.77344 0.0702875 3.75 0.0702875C3.75 0.0702875 3.74219 0.0702875 3.72656 0.0702875C3.71094 0.0702875 3.70313 0.0702875 3.70313 0.0702875L0.199219 2.07348C0.152343 2.12034 0.107422 2.18282 0.0644532 2.26092C0.0214842 2.33901 0 2.42492 0 2.51864V10.5197C0 10.6212 0.0234372 10.7091 0.0703125 10.7833C0.117188 10.8575 0.175781 10.918 0.246094 10.9649C0.324219 11.0195 0.408203 11.0469 0.498047 11.0469C0.587891 11.0469 0.671875 11.0195 0.75 10.9649L3.99609 9.06709L7.74609 10.9649C7.80078 10.9961 7.84375 11.0137 7.875 11.0176C7.90625 11.0215 7.94922 11.0234 8.00391 11.0234C8.02734 11.0234 8.05078 11.0215 8.07422 11.0176C8.09766 11.0137 8.12109 10.9961 8.14453 10.9649C8.14453 10.9649 8.1543 10.9649 8.17383 10.9649C8.19336 10.9649 8.20312 10.9649 8.20312 10.9649C8.20312 10.9649 8.20508 10.9649 8.20898 10.9649C8.21289 10.9649 8.22656 10.9649 8.25 10.9649L11.7539 8.97338C11.8242 8.91871 11.8828 8.85428 11.9297 8.78008C11.9766 8.70589 12 8.61803 12 8.51651V0.527157C12 0.42563 11.9766 0.337771 11.9297 0.263578C11.8828 0.189385 11.8242 0.124956 11.7539 0.0702875ZM4.5 1.32375L7.5 2.82322V9.72311L4.5 8.22364V1.32375ZM0.996094 2.82322L3.50391 1.37061V8.22364L0.996094 9.66454V2.82322ZM11.0039 8.22364L8.49609 9.66454V2.82322L11.0039 1.37061V8.22364Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: "#4d4c4c",
      type: String
    },
    height: {
      default: 11,
      type: [Number, String]
    },
    width: {
      default: 12,
      type: [Number, String]
    }
  }
};
</script>
