<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.63398 1.5C8.01888 0.833332 8.98112 0.833333 9.36602 1.5L14.9952 11.25C15.3801 11.9167 14.899 12.75 14.1292 12.75H2.87083C2.10103 12.75 1.61991 11.9167 2.00481 11.25L7.63398 1.5Z"
      :fill="color1"
    />
    <rect x="7.5" y="4" width="2" height="5" rx="1" :fill="color2" />
    <rect x="7.5" y="9.40002" width="2" height="2" rx="1" :fill="color2" />
  </svg>
</template>

<script>
export default {
  props: {
    color1: {
      default: "#EE0000",
      type: String
    },
    color2: {
      default: "white",
      type: String
    },
    height: {
      default: 17,
      type: [Number, String]
    },
    width: {
      default: 17,
      type: [Number, String]
    }
  }
};
</script>
