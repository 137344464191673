<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49609 5C3.14453 5 2.81641 5.06252 2.51172 5.18757C2.20703 5.32044 1.94336 5.49824 1.7207 5.72098C1.49805 5.94373 1.32031 6.2075 1.1875 6.51231C1.0625 6.81712 1 7.14537 1 7.49707C1 7.84877 1.0625 8.17702 1.1875 8.48183C1.32031 8.78664 1.49805 9.05041 1.7207 9.27315C1.94336 9.4959 2.20703 9.6737 2.51172 9.80657C2.81641 9.93161 3.14453 9.99414 3.49609 9.99414C3.84766 9.99414 4.17578 9.93161 4.48047 9.80657C4.78516 9.6737 5.04883 9.4959 5.27148 9.27315C5.49414 9.05041 5.67187 8.78664 5.80469 8.48183C5.92969 8.17702 5.99219 7.84877 5.99219 7.49707C5.99219 7.14537 5.92969 6.81712 5.80469 6.51231C5.67187 6.2075 5.49414 5.94373 5.27148 5.72098C5.04883 5.49824 4.78516 5.32044 4.48047 5.18757C4.17578 5.06252 3.84766 5 3.49609 5ZM3.49609 8.99766C3.07422 8.99766 2.71875 8.85307 2.42969 8.56389C2.14062 8.27472 1.99609 7.91911 1.99609 7.49707C1.99609 7.07503 2.14062 6.71942 2.42969 6.43025C2.71875 6.14107 3.07422 5.99648 3.49609 5.99648C3.91797 5.99648 4.27344 6.14107 4.5625 6.43025C4.85156 6.71942 4.99609 7.07503 4.99609 7.49707C4.99609 7.91911 4.85156 8.27472 4.5625 8.56389C4.27344 8.85307 3.91797 8.99766 3.49609 8.99766Z"
      :fill="color"
    />
    <rect x="6" y="7" width="4" height="1" :fill="color" />
    <rect x="4" y="1" width="6" height="1" :fill="color" />
    <rect
      x="4.51953"
      y="1.37012"
      width="5.21636"
      height="1"
      transform="rotate(111.719 4.51953 1.37012)"
      :fill="color"
    />
    <rect
      width="2.69621"
      height="1"
      transform="matrix(0.370061 0.929007 0.929007 -0.370061 9 1.37012)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: "#4d4c4c",
      type: String
    },
    height: {
      default: 10,
      type: [Number, String]
    },
    width: {
      default: 12,
      type: [Number, String]
    }
  }
};
</script>
