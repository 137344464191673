<template>
  <div>
    <CoolLightBox
      :items="images"
      :index="index"
      :useZoomBar="true"
      @close="index = null"
    >
    </CoolLightBox>

    <div class="images-wrapper">
      <div
        class="image"
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{
          backgroundImage: `url('${image.src}')`,
          backgroundSize: 'cover'
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CoolLightBox
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      index: null
    };
  }
};
</script>

<style lang="scss" scoped>
.image {
  min-height: 400px;
  cursor: pointer;
  &:hover {
    -webkit-filter: brightness(0.85);
    filter: brightness(0.85);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
</style>
