<template>
  <div>
    <v-divider class="mx-0 exposure-hr"></v-divider>

    <v-card flat class="exposure-detail py-4 px-7">
      <div class="exposure-detail-header" @click="show = !show">
        <span
          class="d-inline-flex mr-4 score justify-center font-weight-bold"
          >{{ item.score }}</span
        >

        <span class="font-color-dark" v-html="item.text"></span>

        <v-btn icon class="close-btn" v-show="item.visualizers.length">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }} </v-icon>
        </v-btn>
      </div>

      <v-expand-transition>
        <div v-show="show">
          <div v-for="(visualizer, index) in item.visualizers" :key="index">
            <component
              :is="visualizer.type"
              :details="visualizer.details"
              :show="show"
            ></component>
          </div>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
// see https://vueschool.io/articles/vuejs-tutorials/lazy-loading-individual-vue-components-and-prefetching/
export default {
  props: ["item"],
  components: {
    Card1: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/Card1"),

    Card2: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/Card2"),

    ClaimDate: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/ClaimDate"),

    Card3: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/Card3"),

    PhotoGrid: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/PhotoGrid"),

    GeoMap: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/GeoMap"),

    ExposureInsight: () =>
      import(
        /* webpackPrefetch: true */ "@/components2/exposures/ExposureInsight"
      ),

    ExposureAction: () =>
      import(
        /* webpackPrefetch: true */ "@/components2/exposures/ExposureAction"
      ),

    Placeholder: () =>
      import(
        /* webpackPrefetch: true */ "@/components/visualizers/Placeholder"
      ),

    BarChart: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/BarChart"),

    Network: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/Network"),

    Weather: () =>
      import(/* webpackPrefetch: true */ "@/components2/exposures/Weather")
  },

  data() {
    return {
      show: false
    };
  },

  mounted() {
    this.$bus.on("exposure-close", this.close);
    this.$bus.on("exposure-open", this.open);
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.exposure-detail-header {
  position: relative;
}

.exposure-detail-header:hover {
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: -7px;
  right: 0;
}

.score {
  color: #8f8f8f;
  min-width: 25px;
}

.exposure-hr {
  width: 96%;
  margin-left: 2% !important;
  border-color: #e9e9e9 !important;
}
</style>
