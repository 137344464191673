<template>
  <div v-if="suspicion" class="suspicion font-color-dark py-1 px-3">
    <AlertIcon class="mt-1 mr-2" />
    <div>
      <span class="font-weight-bold pr-2">Suspicion </span>
      <span v-html="suspicion"></span>
    </div>
  </div>
</template>

<script>
import AlertIcon from "@/components2/icons/AlertIcon";

export default {
  components: { AlertIcon },
  props: {
    suspicion: String
  }
};
</script>

<style lang="scss" scoped>
.suspicion {
  display: flex;
  align-items: center;
  background-color: #baddff;
  border-radius: 6px;
}
</style>
