<template>
  <v-row :class="`mt-4 ${isIframe && 'marginBottom'}`">
    <v-col cols="3">
      <ExposureNav
        :exposures="exposuresNav"
        @selectedExposure="selectedExposure"
      />
    </v-col>
    <v-col cols="9">
      <!--      <Scroller :height="scrollerHeight(windowHeight)" name="exposure-detail">-->
      <v-card
        class="mb-4 pt-1"
        flat
        v-for="(exposure, index) in exposures"
        :key="index"
        v-show="selectedExposureKey == index"
      >
        <ExposureHeader :item="exposure.header" />
        <ExposureDetail
          v-for="(item, index) in exposure.items"
          :item="item"
          :key="index"
        />
      </v-card>
      <!--      </Scroller>-->
    </v-col>
  </v-row>
</template>

<script>
import ExposureNav from "@/components2/exposures/ExposureNav";
import ExposureHeader from "@/components2/exposures/ExposureHeader";
import ExposureDetail from "@/components2/exposures/ExposureDetail";
import Scroller from "@/components2/Scroller";
export default {
  components: {
    ExposureNav,
    ExposureHeader,
    ExposureDetail
    // Scroller
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      showMap: false,
      claimId: "0076-99-143",
      selectedExposureKey: 0
    };
  },

  mounted() {
    this.$bus.on("claims-overview-row-click", (item) => {
      this.claimId = item.claimId;
    });
  },

  methods: {
    selectedExposure(val) {
      this.selectedExposureKey = val;
    }
  },

  computed: {
    nav() {
      if (!this.claimId) return;
      return this.claim.nav;
    },

    claim() {
      return this.$store.state.claims2.filter(
        (d) => d.header.claimId == this.claimId
      )[0];
    },
    claimDetails() {
      // if (!this.claim) return;
      return this.claim.header;
    },

    screeningDetails() {
      // if (!this.claim) return;
      return this.claim.screening;
    },

    exposuresNav() {
      // if (!this.claim) return;
      return this.claim.nav;
    },

    exposures() {
      // if (!this.claim) return;
      return this.claim.exposures;
    },

    isIframe() {
      return this.$store.state.isIframe;
    }
  }
};
</script>

<style lang="scss" scoped>
.marginBottom {
  margin-bottom: 80px;
}
</style>
