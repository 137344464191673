<template>
  <v-container fluid>
    <div class="mt-15">
      <v-row class="d-flex justify-center">
        <v-col cols="4">
          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="password"
            label="Enter password"
            :type="show ? 'text' : 'password'"
            @keyup.enter.native="handlePassword()"
            hint="Ask Peter van Doorn before use"
            @click:append="show = !show"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      password: null
    };
  },

  methods: {
    handlePassword() {
      this.$store.commit("check", btoa(this.password));
    }
  }
};
</script>

<style lang="scss" scoped>
</style>