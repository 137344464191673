<template>
  <v-sheet color="#F1F3F4" :class="`${isIframe && 'iframe'}`">
    <TopNavBar
      v-if="!isIframe"
      @drawer-left-click="drawerLeft = !drawerLeft"
      @drawer-right-click="drawerRight = !drawerRight"
      @drawer-top-click="drawerTop = !drawerTop"
    />

    <v-navigation-drawer
      v-if="!isIframe"
      v-model="drawerLeft"
      fixed
      temporary
      class="drawer"
    >
      <LeftDrawer @drawer-left-close="drawerLeft = false" />
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="!isIframe"
      v-model="drawerRight"
      fixed
      temporary
      right
      class="drawer"
    >
      <component :is="currentComponent" />
    </v-navigation-drawer>

    <v-container
      :class="`fluid justify-center main-wrapper ${isIframe && 'pt-0'}`"
    >
      <router-view></router-view>
    </v-container>

    <v-navigation-drawer
      v-if="!isIframe"
      v-model="drawerTop"
      absolute
      temporary
      class="navigation-drawer-top"
    >
      <v-card flat class="pa-2">top</v-card>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import LeftDrawer from "@/components2/LeftDrawer";
import TopNavBar from "@/components2/TopNavBar";
import Discussion from "@/components2/Discussion";
import eventBus from "@/eventBus";

export default {
  components: {
    LeftDrawer,
    TopNavBar
  },

  data() {
    return {
      show: false,
      overlay: false,
      drawerRight: false,
      drawerLeft: false,
      drawerTop: false,
      currentComponent: null,
      drawerComponentsList: { Discussion }
    };
  },

  mounted() {
    this.$store.commit("setIsIframe", this.$route.query.id);

    eventBus.$on("drawerRight", (payload) => {
      this.currentComponent = this.drawerComponentsList[payload];
      this.drawerRight = !this.drawerRight;
    });
  },

  methods: {
    close() {
      if (this.overlay) {
        this.overlay = !this.overlay;
      }
    }
  },

  computed: {
    isIframe() {
      return this.$store.state.isIframe;
    }
  }
};
</script>

<style>
.main-wrapper {
  min-height: calc(100vh - 66px);
}

.search-container {
  position: absolute;
  top: 64px;
  width: 100%;
  z-index: 2;
  background-color: blue;
  color: white;
}

.base {
  height: calc(100vh - 66px);
}

.v-navigation-drawer.navigation-drawer-top {
  height: 300px !important;
  width: 100% !important;
  transform: translate(0%, -100%) !important;
}

.v-navigation-drawer--open.navigation-drawer-top {
  transform: translateY(0) !important;
}

.container.fluid {
  max-width: 1392px;
  width: 100% !important;
}

.container.fluid .wrapper {
  width: 100% !important;
}

.drawer {
  width: 400px !important;
  z-index: 9000 !important;
}

.v-overlay {
  position: fixed !important;
}

.v-list-item {
  margin: 0 0 5px !important;
}

.theme--light.v-list-item:hover::before {
  opacity: 0.1;
  background-color: #1b1b1b;
}

.iframe {
  height: 100vh;
}
</style>
