<template>
  <div>
    <div
      class="
        top
        py-2
        px-3
        mb-7
        d-flex
        justify-center
        align-center
        header header-container
      "
    >
      <v-container fluid>
        <v-row class="py-3 align-center d-flex justify-space-between">
          <div class="detail-col">
            <div class="item-title">Claim Id</div>
            <div class="item-value">{{ details.claim }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Label</div>
            <div class="item-value">{{ details.label }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Product</div>
            <div class="item-value">{{ details.product }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Process</div>
            <div class="item-value">{{ details.process }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Branch</div>
            <div class="item-value">{{ details.branch }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Policy Id</div>
            <div class="item-value">{{ details.policy_id }}</div>
          </div>
          <div class="detail-col">
            <div class="item-title">Reserved amount</div>
            <div class="item-value">{{ details.reserved_amount }}</div>
          </div>
          <div class="detail-col screening">
            <span class="item-title pr-1">Screening Id</span>
            <span class="item-title font-weight-bold">{{
              details.screening_id
            }}</span>
            <div class="item-value">{{ details.date }}</div>
          </div>

          <div class="detail-col align-center">
            <div>
              <v-btn icon disabled>
                <v-icon size="30" color="#4D4C4C">mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon size="30" color="#4D4C4C">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="detail-col align-center">
            <v-btn icon>
              <v-badge right color="#FF5875">
                <template v-slot:badge>
                  <span class="font-weight-bold chat-icon">2</span>
                </template>
                <img :src="details.chatIcon" alt="" />
              </v-badge>
            </v-btn>
          </div>
        </v-row>
      </v-container>
    </div>

    <div class="tabs">
      <v-tabs height="38" v-model="selection" background-color="transparent">
        <v-tabs-slider color="#1A8DFF"></v-tabs-slider>

        <v-tab
          v-for="(item, index) in items"
          :key="index"
          class="pl-2"
          :to="item.to"
        >
          <span style="color: #4d4c4c" class="d-flex align-center font-14">
            <img :src="item.icon" alt="" class="pr-1" />
            <span class="ml-1 text-capitalize">{{ item.name }}</span>
            <span v-if="item.count"> ({{ item.count }})</span>
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selection">
        <v-tab-item v-for="item in items" :key="item.name">
          <component :is="item.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import DiagnosticsViewer from "./DiagnosticsViewer.vue";
import ExposureViewer from "./ExposureViewer.vue";
import NetworkViewer from "./NetworkViewer.vue";

import exposure from "@/assets/svg/exposure.svg";
import audit from "@/assets/svg/audit.svg";
import screening from "@/assets/svg/screening.svg";
import network from "@/assets/svg/network.svg";
import chat from "@/assets/svg/chat.svg";

export default {
  data() {
    return {
      selection: 0,
      items: [
        {
          name: "Indicators",
          icon: exposure,
          component: ExposureViewer
        },
        { name: "Network view", icon: network, component: NetworkViewer },
        {
          name: "Screening diagnostics",
          icon: screening,
          component: DiagnosticsViewer
        },
        { name: "Audit trail", icon: audit, component: null }
      ],
      details: {
        claim: "0076-99-143",
        label: "TestInsurer",
        product: "PersonalAutoLine",
        process: "Claim process",
        branch: "NoLife",
        reserved_amount: "$ 500",
        policy_id: "0076-99-143",
        screening_id: "64332",
        date: "July 28, 2021 11:23 AM",
        chatIcon: chat
      }
    };
  },

  methods: {
    detailItemGridClass(itemIndex) {
      return itemIndex === 2 ? "col-5 detail-col" : "col-3 detail-col";
    },

    checkType(type) {
      return typeof type == "object";
    }
  }
};
</script>

<style lang="scss">
.header {
  .screening {
    padding-left: 100px;
  }
}

.v-badge__badge {
  top: -12px !important;
  left: 8px !important;
}

.v-tabs {
  .v-tabs-bar {
    border-top: 1px solid #e9e9e9 !important;
    border-bottom: 1px solid #e9e9e9 !important;
  }
}

.top {
  position: relative;
  background-color: white;
  border-radius: 6px;
  z-index: 2;
}

.header-container {
  height: 64px;
}

.pointer:hover {
  cursor: pointer;
}

.bottom {
  position: relative;
  background-color: #ff5875;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 6px;
  margin-top: -6px;
  z-index: 1;
  color: white;
}

.item-title {
  font-size: 12px;
  color: #8f8f8f;
}

.item-value {
  font-size: 14px;
  font-weight: bold;
  color: #4d4c4c;
}
</style>
