<template>
  <div class="observable d-flex align-center my-5" :id="item.exposureId">
    <div
      @click="handleClick"
      class="
        ml-4
        exposure-score
        d-flex
        justify-center
        align-center
        font-weight-bold
      "
      :style="{
        color: item.scoreColor,
        border: `1px solid ${item.scoreColor}`
      }"
    >
      {{ item.score }}
    </div>

    <div class="ml-4">
      <div class="text-h6">{{ item.header }}</div>
      <div class="text-body-2 grey--text">
        {{ item.coverage }} -
        <span class="font-weight-black black--text">{{
          item.amountClaimed
        }}</span>
        ({{ item.amountAtRisk }} remaining)
      </div>

      <div class="text-subtitle-2">
        {{ item.subHeader }}
        <v-btn small text color="blue"> show all details </v-btn>
      </div>
    </div>

    <v-spacer></v-spacer>

    <div class="mr-4">
      <div class="d-flex justify-end align-center">
        <v-avatar size="26px" class="img-assigned mr-4">
          <img alt="person" :src="require('@/assets/img/person.png')" />
        </v-avatar>

        <v-btn large color="blue" dark
          >{{ item.status }}<v-icon>mdi-menu-down</v-icon></v-btn
        >
      </div>

      <div class="mt-1" v-if="item.suggestion">
        <div class="float-right">
          <span class="text-right text-overline"> We suggest:</span
          ><v-btn small text :color="item.suggestionColor">{{
            item.suggestion
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["item"],

  data() {
    return {
      open: false
    };
  },

  methods: {
    handleClick() {
      this.open = !this.open;

      this.$bus.emit(this.open ? "exposure-open" : "exposure-close");
    }
  }
};
</script>

<style lang="scss" scoped>
.exposure-score {
  width: 80px;
  height: 80px;
  border-radius: 3px;
  font-size: 28px;
  user-select: none;
}

.exposure-score:hover {
  cursor: pointer;
}

.img-assigned {
  border: 1px solid #2196f3;
}
</style>
