<template>
  <v-card class="elevation-0 pa-4">
    <v-row>
      <v-col cols="6">
        <DisplayImage :images="currentImage" :currentIndex="currentIndex" />
      </v-col>

      <v-col v-if="image" cols="6">
        <div class="d-flex justify-space-between align-center">
          <div class="pb-5">
            <div class="font-12 font-color-light">Photo</div>
            <div class="font-weight-bold font-color-dark">
              {{ image.name }}
            </div>
          </div>
          <div class="exif text-decoration-underline">Show EXIF data</div>
        </div>
        <div class="pb-5">
          <div class="font-12 font-color-light">Provided by</div>
          <div class="font-weight-bold font-color-dark">
            {{ image.providedBy }}
          </div>
          <div>{{ image.providerType }}</div>
        </div>

        <div>
          <div class="font-weight-bold font-color-dark mt-5 mb-2">
            Image screening irregularities
          </div>

          <v-expansion-panels v-if="image.irregularities.length" accordion flat>
            <v-expansion-panel
              v-for="(irregularity, index) in image.irregularities"
              :key="index"
            >
              <div
                class="
                  d-flex
                  align-center
                  justify-space-between
                  irregularity-panel-item
                "
              >
                <v-icon color="#EE0000" class="pl-3"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-expansion-panel-header class="px-2">{{
                  irregularity.title
                }}</v-expansion-panel-header>
              </div>
              <v-expansion-panel-content>
                {{ irregularity.details }}
                <a
                  v-if="irregularity.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  :href="irregularity.link"
                >
                  {{ irregularity.link }}
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else>None found</div>

          <v-divider></v-divider>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DisplayImage from "@/components2/photos/DisplayImage.vue";

export default {
  components: {
    DisplayImage
  },

  props: {
    images: {
      type: Array
    },
    image: {
      type: [Object, Number]
    },
    currentIndex: {
      type: Number
    }
  },

  computed: {
    currentImage() {
      return [this.images[this.currentIndex]];
    }
  }
};
</script>

<style lang="scss" scoped>
.exif {
  cursor: pointer;
}

.v-expansion-panel {
  .irregularity-panel-item {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
