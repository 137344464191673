const config = {

    app_version: "2.0",

    entities: [
        "claim",
        "screening",
        "person",
        "vehicle",
        "policy",
        "realty",
        "company",
        "nicb",
        "caseresult"
    ],

    node_styles: {

        caseresult: {
            icon: "fa-archive",
            color: "#FF5722",
            icon_code: "\uf187",
            icon_color: "white"
        },

        datasourcehit: {
            icon: "fa-database",
            color: "#616161",
            icon_code: "\uf1c0",
            icon_color: "white"
        },

        rentalticket: {
            icon: "fa-ticket",
            color: "#00BCD4",
            icon_code: "\uf145",
            icon_color: "white"
        },

        note: {
            icon: "fa-pencil-square-o",
            color: "#FFC107",
            icon_code: "\uf044",
            icon_color: "white"
        },

        screening: {
            icon: "fa-bell",
            color: "#2196F3",
            icon_code: "\uf05a",
            icon_color: "white"
        },

        claim: {
            icon: "fa-folder-open",
            color: "#FF9800",
            icon_code: "\uf07c",
            icon_color: "white"
        },

        policy: {
            icon: "fa-file",
            color: "#9C27B0",
            icon_code: "\uf15b",
            icon_color: "white"
        },

        person: {
            icon: "fa-user",
            color: "#009688",
            icon_code: "\uf007",
            icon_color: "white"
        },

        vehicle: {
            icon: "fa-car",
            color: "#E91E63",
            icon_code: "\uf1b9",
            icon_color: "white"
        },

        realty: {
            icon: "fa-home",
            color: "#00BCD4",
            icon_code: "\uf015",
            icon_color: "white"
        },

        company: {
            icon: "fa-building",
            color: "#795548",
            icon_code: "\uf1ad",
            icon_color: "white"
        },

        legalentity: {
            icon: "fa-question-circle",
            color: "#607D8B",
            icon_code: "\uf059",
            icon_color: "white"
        },

        LastName: {
            icon: "fa-user",
            color: "#757575",
            icon_code: "\uf007",
            icon_color: "white"
        },

        FullName: {
            icon: "fa-user",
            color: "#757575",
            icon_code: "\uf007",
            icon_color: "white"
        },

        MakeModel: {
            icon: "fa-car",
            color: "#757575",
            icon_code: "\uf1b9",
            icon_color: "white"
        },

        Birthdate: {
            icon: "fa-calendar",
            color: "#757575",
            icon_code: "\uf073",
            icon_color: "white"
        },

        ContactEmail: {
            icon: "fa-envelope-o",
            color: "#757575",
            icon_code: "\uf003",
            icon_color: "white"
        },

        FullAddress: {
            icon: "fa-map-marker",
            color: "#757575",
            icon_code: "\uf041",
            icon_color: "white"
        },

        ContactMobile: {
            icon: "fa-mobile",
            color: "#757575",
            icon_code: "\uf10b",
            icon_color: "white"
        },

        ContactFax: {
            icon: "fa-fax",
            color: "#757575",
            icon_code: "\uf1ac",
            icon_color: "white"
        },

        BankAccount: {
            icon: "fa-money",
            color: "#757575",
            icon_code: "\uf0d6",
            icon_color: "white"
        },

        ContactPhone: {
            icon: "fa-phone",
            color: "#757575",
            icon_code: "\uf095 ",
            icon_color: "white"
        },

        feedback: {
            icon: "fa-bell",
            color: "#757575",
            icon_code: "\uf0f3",
            icon_color: "white"
        },

        IdentificationNumber: {
            icon: "fa-id-card",
            color: "#757575",
            icon_code: "\uf2c2",
            icon_color: "white"
        },

        nicb: {
            icon: "fa-bell-o",
            color: "#FF1744",
            icon_code: "\uf0f3",
            icon_color: "white",
            tile: true
        },

        default: {
            icon: "fa-asterisk",
            color: "#424242",
            icon_code: "\uf069",
            icon_color: "white"
        }
    },
};

export default config;
