<template>
  <div>
    <!-- bottom row -->
    <v-row class="mx-2 mt-2 line-height-small">
      <!-- left  -->
      <v-col cols="3" class="py-0">
        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">STATUS</div>
            <div class="text-body-2 text-capitalize">{{ item.status }}</div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">deadline</div>
            <div class="text-body-2">{{ item.deadline }}</div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">loss date</div>
            <div class="text-body-2">{{ item.lossDate }}</div>
          </div>
        </div>
      </v-col>

      <!-- middle -->
      <v-col cols="7" class="py-0">
        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">Insured</div>
            <div class="text-body-2">{{ item.insured }}</div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">POLICY #</div>
            <div class="text-body-2">{{ item.policyId }}</div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">Location</div>
            <div class="text-body-2">
              <v-btn icon x-small>
                <v-icon color="blue">mdi-map-marker</v-icon>
              </v-btn>
              {{ item.location }}
            </div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">PHOTOS</div>
            <div class="text-body-2">
              <v-btn icon x-small @click="$emit('photo-click')">
                <v-icon color="blue">mdi-image</v-icon>
              </v-btn>
              {{ item.photoCount }}
            </div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">claimed</div>
            <div class="text-body-2">{{ item.amountClaimed }}</div>
          </div>
        </div>

        <div class="d-inline-block mr-4">
          <div>
            <div class="grey--text text-overline">AT RISK</div>
            <div class="text-body-2 font-weight-black">
              {{ item.amountAtRisk }}
            </div>
          </div>
        </div>
      </v-col>

      <!-- right -->
      <v-col cols="2" class="py-0">
        <div class="d-flex align-center justify-end">
          <div class="text-right">
            <div>
              <div class="grey--text text-overline">screening</div>
              <div class="text-body-2 screening-id">
                <v-tooltip left transition="fade-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      >{{ item.screeningId }}
                    </span>
                  </template>
                  <div>
                    <div>{{ item.screeningDate }}</div>
                    <div>By Niek</div>
                  </div>
                </v-tooltip>
              </div>
            </div>
          </div>

          <div>
            <v-btn small icon><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-btn small icon><v-icon>mdi-chevron-right</v-icon></v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.screening-id:hover {
  cursor: pointer;
}

.line-height-small * {
  line-height: 1.4rem;
}
</style>