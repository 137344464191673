<template>
  <v-container fluid class="myGrid">
    <ClaimDetails2 class="claim-details" :item="claimDetails" />

    <ScreeningDetails class="screening-details" :item="screeningDetails" />

    <div class="exposure-nav pt-3">
      <ExposureNav :exposures="exposuresNav" />
    </div>

    <div class="exposure-details">
      <div class="exposure-wrapper mt-3 ml-3">
        <Scroller :height="730" name="exposure-detail">
          <v-card
            class="mb-4 pt-1"
            v-for="(exposure, index) in exposures"
            :key="index"
            id="#observer-root"
          >
            <ExposureHeader :item="exposure.header" />

            <ExposureDetail
              v-for="(item, index) in exposure.items"
              :item="item"
              :key="index"
              :id="`exposure-detail-${index}`"
            />

            <v-divider></v-divider>

            <v-card flat class="exposure-detail px-10 pt-2 pb-3">
              <v-btn small text color="blue">show all indicators</v-btn>
            </v-card>
          </v-card>
          <div class="v-spacer"></div>
        </Scroller>
      </div>
    </div>
  </v-container>
</template>

<script>
import ClaimDetails2 from "@/components/ClaimDetails2";
import ScreeningDetails from "@/components/ScreeningDetails";
import ExposureNav from "@/components/ExposureNav";
import ExposureHeader from "@/components/ExposureHeader";
import ExposureDetail from "@/components/ExposureDetail";
import Scroller from "@/components/Scroller";

export default {
  mounted() {
    this.$bus.on("claims-overview-row-click", (item) => {
      this.claimId = item.claimId;
    });
  },

  components: {
    ClaimDetails2,
    ScreeningDetails,
    ExposureNav,
    ExposureHeader,
    ExposureDetail,
    Scroller
  },

  data() {
    return {
      showMap: false,
      claimId: "0076-99-143"
    };
  },

  computed: {
    nav() {
      if (!this.claimId) return;
      return this.claim.nav;
    },

    claim() {
      return this.$store.state.claims.filter(
        (d) => d.header.claimId == this.claimId
      )[0];
    },

    claimDetails() {
      // if (!this.claim) return;
      return this.claim.header;
    },

    screeningDetails() {
      // if (!this.claim) return;
      return this.claim.screening;
    },

    exposuresNav() {
      // if (!this.claim) return;
      return this.claim.nav;
    },

    exposures() {
      // if (!this.claim) return;
      return this.claim.exposures;
    }
  },

  watch: {
    $route: {
      immediate: true,

      handler(to, from) {
        const id = to.params.id;

        if (id) {
          this.claimId = id;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.myGrid {
  display: grid;

  grid-template-columns: 25% 75%;

  grid-template-rows: minmax(60px, auto) 60px calc(
      100vh - 64px - 60px - 60px - 8px
    );

  grid-template-areas:
    "claim-details claim-details"
    "screening-details screening-details"
    "exposure-nav exposure-details";

  grid-gap: 0px;

  justify-items: stretch;
  overflow: hidden;
}

.claim-details {
  grid-area: claim-details;
  border-bottom: 1px solid rgb(232, 234, 234);
}

.screening-details {
  grid-area: screening-details;
  background-color: rgb(249, 250, 250);
  border-bottom: 1px solid rgb(232, 234, 234);
}

.exposure-nav {
  grid-area: exposure-nav;
  padding: 10px;
  background-color: rgb(245, 247, 247);
  border-right: 1px solid rgb(232, 234, 234);
}

.exposure-details {
  grid-area: exposure-details;
  padding: 10px;
  background-color: rgb(245, 247, 247);
}

.exposure-wrapper {
  overflow: hidden;
  height: calc(100vh - 64px - 60px - 60px - 15px);
}

.v-spacer {
  min-height: 30vh;
}
</style>
