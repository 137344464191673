// css imports
import "overlayscrollbars/css/OverlayScrollbars.css";
import "@/assets/css/overlay_scrollbars_custom.css";
import "billboard.js/dist/billboard.css";
import "./assets/css/global.css";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { store } from "./store";
import router from "./router";
import msal from "vue-msal";

Vue.use(msal, {
  auth: {
    clientId: "5539c5f9-5ed1-4eff-ba49-0e7d9f7806c4",
    tenantId: "8a1ff3f4-bdf8-40ad-9c57-371802296466",
    redirectUri: () => window.location.origin.split("#")[0]
  }
});

// mitt (used for event bus)
import mitt from "mitt";

// overlay scrollbar
import { OverlayScrollbarsPlugin } from "overlayscrollbars-vue";

import {
  colorOfScore,
  colorOfScoringClassBg,
  colorOfScoringClassText,
  scrollerHeight
} from "./helpers";

Vue.use(OverlayScrollbarsPlugin);

Vue.config.productionTip = false;

// create event bus
Vue.prototype.$bus = mitt();

// mixin
Vue.mixin({
  methods: {
    colorOfScore,
    colorOfScoringClassBg,
    colorOfScoringClassText,
    scrollerHeight
  }
});

new Vue({
  vuetify,
  router,
  store: store,
  render: (h) => h(App),
  created() {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
  }
}).$mount("#app");
