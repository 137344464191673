import carImageHummer from "@/assets/img/hummer.jpg";
import carImageAccord from "@/assets/img/accord.jpg";
import overviewImage from "@/assets/img/car-overview.png";
import personImage from "@/assets/img/person.png";
import chat from "@/assets/svg/chat.svg";
import historyClaimImage from "@/assets/img/claim-history-ss.png";

export const claims2 = [
  {
    nav: [
      {
        exposureId: 1,
        score: 950,
        header: "Meg Smith",
        footer: "1st party vehicle total loss",
        flagged: true,
        status: "Open"
      }
    ],

    header: {
      claimId: "0076-87-876",
      type: "One-sided collision",
      status: "Open",
      deadline: "12 days",
      lossDate: "21-02-2021 (04:21 PM)",
      insured: "Meg Smith",
      policyId: "987138761",
      location: "Saint-Raymond, Quebec",
      photoCount: 4,
      amountClaimed: "$75,000",
      amountAtRisk: "$75,000",
      screeningId: "88762349898",
      screeningDate: "23-02-2021",
      frissScore: 750,
      chatIcon: chat,
      assignedTo: [
        {
          image: "https://randomuser.me/api/portraits/women/65.jpg",
          name: "Maria Fernandez"
        },
        {
          image: personImage,
          name: "Olivia Pierce"
        }
      ],
      screening: "1 out of 8"
    },

    screening: {
      header: "85% chance of a staged accident",
      risk: "high risk",
      color: "red"
    },

    vehicles: [
      {
        brand: "Hummer",
        vin: "1C4AJWBG5HL533650",
        year: "2021",
        image: carImageHummer,
        overview: overviewImage,
        askFor: [
          "How fast were you driving?",
          "Why is there an inconsistency between damage in the photo and the police report?"
        ],
        plate: "IKT3475",
        passengers: [
          {
            fullName: "Meg Smith",
            description: "Driver of the vehicle"
          }
        ]
      },
      {
        brand: "Honda Accord",
        vin: "1C4AJWBG5HL533650",
        year: "2014",
        image: carImageAccord,
        overview: overviewImage,
        askFor: ["How fast were you going?"],
        plate: "MNB8761",
        passengers: [
          {
            fullName: "Brad Lee",
            description: "3rd party driver (Uber)"
          },
          {
            fullName: "Chris Matherson",
            description: "Passenger"
          },
          {
            fullName: "Samantha Ramirez",
            description: "Passenger"
          }
        ]
      }
    ],

    parties: [
      {
        name: "Meg Smith",
        description: "1st Party Bodily Injury",
        type: "Driver of insured’s vehicle",
        contact: true,
        address: "2405 Santa Clara Drive, Las Vegas, NV, USA",
        coordinates: { lat: 36.14481012103454, lng: -115.1530557155561 },
        image: "https://randomuser.me/api/portraits/women/71.jpg",
        image_source: "LinkedIn",
        phone: "709-555-1234",
        askFor: ["Medical history", "If works in Insurance Industry"],
        exposures: [
          {
            title: "Bodily Injury",
            subtitle: "Broken leg",
            cost: "$10,000",
            score: 500
          }
        ],
        history_claim: historyClaimImage
      },
      {
        name: "Chris Matherson",
        description: "3rd Party Bodily Injury",
        type: "Passenger",
        contact: false,
        address: "7629 Belmondo Ln, Las Vegas, NV, USA",
        coordinates: { lat: 36.205909650541564, lng: -115.26299100206037 },
        image: "https://randomuser.me/api/portraits/men/33.jpg",
        image_source: "Database",
        phone: "707-566-3781",
        askFor: ["Relation to owner of vehicle", "Medical history"],
        exposures: [
          {
            title: "Bodily Injury",
            subtitle: "Whiplash",
            cost: "$50,000",
            score: 150
          }
        ]
      },
      {
        name: "Samantha Ramirez",
        description: "3rd Party Bodily Injury",
        type: "Passenger",
        contact: false,
        address: "Terminal Way, Reno, NV 89502, USA",
        coordinates: { lat: 39.51108595794193, lng: -119.773027544322 },
        image: "https://randomuser.me/api/portraits/women/76.jpg",
        image_source: "Database",
        phone: "608-688-2169",
        askFor: [
          "Relation to owner of vehicle",
          "Occupation",
          "Medical history"
        ],
        exposures: [
          {
            title: "Bodily Injury",
            subtitle: "Broken nose",
            cost: "$10,000",
            score: 100
          }
        ]
      },
      {
        name: "Brad Lee",
        description: "3rd Party Vehicle",
        type: "3rd party driver (Uber)",
        contact: false,
        address: "2321 Santa Rita Drive, Las Vegas, NV, USA",
        coordinates: { lat: 36.144422377570386, lng: -115.15140957322652 },
        image: "https://randomuser.me/api/portraits/men/89.jpg",
        image_source: "Facebook",
        phone: "608-688-2169",
        askFor: ["Relation to owner of vehicle"],
        exposures: [
          {
            title: "No Injury",
            subtitle: "Car damages",
            cost: "$5,000",
            score: 0
          }
        ]
      }
    ],

    addresses: [
      {
        position: { lat: 36.14481012103454, lng: -115.1530557155561 },
        type: "house",
        name: "Meg Smith",
        personType: "Driver, Claimant",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      },
      {
        position: { lat: 36.205909650541564, lng: -115.26299100206037 },
        type: "house",
        name: "Chris Matherson",
        personType: "Passenger",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      },
      {
        position: { lat: 39.51108595794193, lng: -119.773027544322 },
        type: "house",
        name: "Samantha Ramirez",
        personType: "Passenger",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      },
      {
        position: { lat: 36.144422377570386, lng: -115.15140957322652 },
        type: "house",
        name: "Brad Lee",
        personType: "3rd party driver (Uber)",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      },
      {
        position: { lat: 36.0855686, lng: -115.200831 },
        type: "accident",
        name: "Crash location",
        personType: "Accident",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      }
    ],

    images: [
      {
        path: carImageHummer,
        name: "hummer.jpg",
        providedBy: "Meg Smith",
        providerType: "Claimant",
        irregularities: [
          {
            title: "Photo is older",
            details:
              "Photo appears to have been taken 9 months prior to claim date"
          },
          {
            title: "Photo found online",
            link: "https://www.pinterest.com/pin/322851867024269259/"
          }
        ]
      },
      {
        path: carImageAccord,
        name: "honda-accord.jpg",
        providedBy: "Brad Lee",
        providerType: "3rd party driver",
        irregularities: []
      }
    ],

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 950,
          scoreColor: "red",
          header: "Meg Smith - 1st party vehicle total loss",
          coverage: "Collision coverage",
          amountClaimed: "$ 31,900",
          amountAtRisk: "$ 31,900",
          subHeader: "2017 Jeep Wrangler Sport",
          status: "Open",
          suggestion: "send to SIU",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 100,
            text: `Claim history indicates an <span class="font-weight-black"> earlier total loss claim with the same VIN</span>.`,
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  items: [
                    { text: "claim", value: "0076-34-887 / 12-12-2020" },
                    { text: "claimed", value: "$ 33,600" },
                    {
                      text: "status",
                      value: "Open",
                      bold: true
                    },
                    { text: "claimant", value: "Didier le Fevre" },
                    { text: "vin", value: "1C4AJWBG5HL533650" }
                  ],
                  footer: "Go to claim"
                }
              }
            ]
          },

          {
            score: 100,
            text: `<span class="font-weight-black">Image screening irregularities</span> found (4/4)`,
            visualizers: [{ type: "PhotoGrid", data: { id: 2 } }]
          },

          {
            score: 100,
            text: ` Same vehicle was found damaged on an <span class="font-weight-black">online market place</span>`,
            visualizers: [{ type: "Card2", data: { id: 3 } }]
          },

          {
            score: 50,
            text: `The location of the claim is in a <span class="font-weight-black">remote area</span>.`,
            visualizers: [
              {
                type: "GeoMap",
                details: {
                  coordinates: [46.8815833, -71.8513276],
                  zoom: 14,
                  height: 400,
                  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                  markers: [
                    {
                      id: "1",
                      text: "The location of the claim is in a remote area.",
                      coordinates: [46.8815833, -71.8513276]
                    }
                  ]
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Car damage fraud is 23% more likely in remote areas due to possible lack of witnesses.`
                }
              }
            ]
          }
        ]
      }
    ]
  },

  {
    nav: [
      {
        exposureId: 1,
        score: 500,
        header: "Meg Smith",
        footer: "1st Party Bodily Injury",
        flagged: true,
        status: "open"
      },

      {
        exposureId: 2,
        score: 150,
        header: "Chris Matherson",
        footer: "3rd Party Bodily Injury",
        flagged: false,
        status: "open"
      },

      {
        exposureId: 3,
        score: 100,
        header: "Samantha Ramirez",
        footer: "3rd Party Bodily Injury",
        flagged: false,
        status: "closed"
      },

      {
        exposureId: 4,
        score: 0,
        header: "Brad Lee",
        footer: "3rd Party Vehicle",
        flagged: false,
        status: "closed"
      }
    ],

    header: {
      claimId: "0076-99-143",
      type: "Accident benefits due to head-to-head collision",
      status: "New",
      deadline: "2 days",
      lossDate: "11-02-2021 (11:45 AM)",
      insured: "Ramona Wilde",
      policyId: "98553916",
      location: "Englehart, Ontario",
      photoCount: 0,
      amountClaimed: "$75,000",
      amountAtRisk: "$45,900",
      screeningId: "88711684765",
      screeningDate: "13-02-2021"
    },

    screening: {
      header: "90% chance of pre-existing injuries",
      risk: "high risk",
      color: "red"
    },

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 500,
          scoreColor: "red",
          header: "Meg Smith",
          coverage: "Claimant, Driver",
          amountClaimed: "$10,000",
          amountAtRisk: "$10,000",
          subHeader: "Broken leg",
          status: "Open",
          suggestion: "nudge SIU",
          suggestionColor: "blue",
          suspicion: `91% Probability of Scheme Type: <b>STAGED ACCIDENT</b>`
        },

        items: [
          {
            score: 200,
            text: `<strong>Claimant</strong> has <strong>6 NICB forewarns alerts</strong>`,
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  items: [
                    { text: "claim", value: "0076-12-994 / 06-07-2021" },
                    { text: "claimed", value: "$10,000" },
                    {
                      text: "status",
                      value: "Closed - Accepted",
                      bold: true
                    },
                    { text: "claimant", value: "Meg Smith" },
                    { text: "injury", value: "Broken leg" }
                  ],
                  footer: "Go to claim"
                }
              }
            ]
          },

          {
            score: 100,
            text: `Injury <strong>claim without medical assistance</strong>`,
            visualizers: [
              {
                type: "BarChart",
                details: {
                  data: [
                    {
                      id: 0,
                      year: "M. Smith",
                      value: 10000,
                      fill: "#EE0000",
                      labelTop: "",
                      labelBottom: "(this exposure)"
                    },
                    {
                      id: 1,
                      year: "C. Matherson",
                      value: 50000,
                      fill: "#4D4C4C"
                    },
                    {
                      id: 2,
                      year: "S. Ramirez",
                      value: 10000,
                      fill: "#4D4C4C"
                    },
                    { id: 3, year: "B. Lee", value: 5000, fill: "#4D4C4C" }
                  ],
                  width: 600,
                  height: 200,
                  padding: 0.8,
                  hoverColor: "rgba(0,0,0,0.5)"
                }
              }
            ]
          },

          {
            score: 50,
            text: `Accident <strong>occurred in Las Vegas, NV</strong> and <strong>claimant rented a vehicle in the city of residence</strong>`,
            visualizers: [
              {
                type: "GeoMap",
                details: {
                  coordinates: [36.14481012103454, -115.1530557155561],
                  zoom: 11,
                  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                  markers: [
                    {
                      id: "1",
                      text: `2405 Santa Clara Drive, Las Vegas, NV, USA.<br/><b>Claimant home</b>`,
                      coordinates: [36.14481012103454, -115.1530557155561]
                    },

                    {
                      id: "2",
                      text: `5720 Arville St, Las Vegas, NV 89118, USA<br/><b>Accident Location</b>`,
                      coordinates: [36.0855686, -115.200831]
                    }
                  ]
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Rented vehicle in the city of residence indicates a 14% higher possibility of fraud scheme.`
                }
              }
            ]
          },

          {
            score: 100,
            text: `<strong>Claimant claims it rained</strong>, while accident <strong>occurred during clear weather</strong>`,
            visualizers: [
              {
                type: "Weather",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `Claimant said that tried to avoid pedestrians.`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              }
            ]
          },
          {
            score: 50,
            text: `Claimant seems to be using <strong>language which indicates Insurance Industry knowledge.</strong>`,
            visualizers: [
              {
                type: "Card3",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `Claimant said that tried to avoid pedestrians.`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              },

              {
                type: "ExposureAction",
                details: {
                  text: `Ask if the insured works for insurance industry`
                }
              }
            ]
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-2",
          score: 150,
          scoreColor: "green",
          header: "Chris Matherson - Passenger",
          coverage: "AB coverage",
          amountClaimed: "$50,000",
          amountAtRisk: "$50,000",
          subHeader: "Whiplash",
          status: "Open",
          suggestion: "accept",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 100,
            text: `Linked to a <span class="font-weight-black">fraudulent claim in 2 steps</span>.`,
            visualizers: [
              {
                type: "Network",
                details: {
                  height: 420,
                  network: {
                    nodes: [
                      { id: 1, node_type: "claim", label: "This claim" },
                      {
                        id: 2,
                        node_type: "person",
                        label: "Chris Matherson",
                        outerCircle: "#2196F3"
                      },
                      { id: 3, node_type: "LastName", label: "Matherson" },
                      { id: 4, node_type: "person", label: "Laura Matherson" },
                      { id: 5, node_type: "claim", label: "66543 987" },
                      { id: 6, node_type: "person", label: "Paul Beagal" },
                      {
                        id: 7,
                        node_type: "claim",
                        label: "97651 876",
                        outerCircle: "#F44336"
                      }
                    ],

                    links: [
                      { source: 1, target: 2 },
                      { source: 2, target: 3 },
                      { source: 2, target: 4, color: "red" },
                      { source: 3, target: 4 },
                      { source: 4, target: 5, color: "red" },
                      { source: 5, target: 6, color: "red" },
                      { source: 6, target: 7, color: "red" }
                    ]
                  }
                }
              },

              {
                type: "ExposureInsight",
                details: {
                  text: `Being linked to a fraudulous claim in 2 steps on average has a 24% higher risk.`
                }
              }
            ]
          },
          {
            score: 50,
            text: `Injury exposure has a <strong>substantial higher reserve</strong> than other injury exposures in the claim`,
            visualizers: [
              {
                type: "Card3",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `Substantial higher reserve than the rest of the people in this claim.`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              },

              {
                type: "ExposureInsight",
                details: {
                  text: `Substantial higher reserve claims are more likely of 19% of being fraudulent.`
                }
              }
            ]
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-3",
          score: 100,
          scoreColor: "green",
          header: "Samantha Ramirez - Passenger",
          coverage: "AB coverage",
          amountClaimed: "$10,000",
          amountAtRisk: "$0 remaining",
          subHeader: "Broken nose",
          status: "Open"
        },

        items: [
          {
            score: 100,
            text: `Linked to a <span class="font-weight-black">fraudulent claim in 2 steps</span>.`,
            visualizers: [
              {
                type: "Card3",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `Being linked to a fraudulous claim in 2 steps on average has a 24% higher risk.`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              }
            ]
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-4",
          score: 0,
          scoreColor: "green",
          header: "Brad Lee - 3rd party driver (Uber)",
          coverage: "Collision coverage",
          amountClaimed: "$5,000",
          amountAtRisk: "$0 remaining",
          subHeader: "Car damages",
          status: "Open"
        },

        items: [
          {
            score: 0,
            text: `No suspicion found`,
            visualizers: []
          }
        ]
      }
    ]
  },

  {
    nav: [
      {
        exposureId: 1,
        score: 200,
        header: "Peter Sharpe",
        footer: "1st party partial theft",
        flagged: true,
        status: "Open"
      }
    ],

    header: {
      claimId: "0076-77-983",
      type: "Property theft from vehicle",
      status: "Open",
      deadline: "5 days",
      lossDate: "11-01-2021 (05:30 AM)",
      insured: "Peter Sharpe",
      policyId: "987129871",
      location: "Saint-Raymond, Quebec",
      photoCount: 0,
      amountClaimed: "$ 5,600",
      amountAtRisk: "$ 5,600",
      screeningId: "98713409870",
      screeningDate: "18-02-2021"
    },

    screening: {
      header: "75% chance of ghost brokering",
      risk: "high risk",
      color: "red"
    },

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 200,
          scoreColor: "red",
          header: "Peter Sharpe - 1st party partial theft",
          coverage: "Comprehensive",
          amountClaimed: "$ 5,600",
          amountAtRisk: "$ 5,600",
          subHeader: "Laptop, camera & smashed window",
          status: "Open",
          suggestion: "reject",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 50,
            text: "Claim <span class='font-weight-black'>occurred during the policy's lifecycle</span>, but was <span class='font-weight-black'>reported more than 30 day after the expiry</span> of the policy",
            visualizers: [
              {
                type: "ClaimDate",
                details: {}
              }
            ]
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>> $5,000 claim</span> reported <span class='font-weight-black'>more than 30 days (35 days) after occurrence</span>",
            visualizers: []
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>Email address</span> was used in <span class='font-weight-black'>multiple claims and policies</span> with <span class='font-weight-black'>different names</span>",
            visualizers: [
              {
                type: "Network",
                details: {
                  height: 420,
                  network: {
                    nodes: [
                      { id: 1, node_type: "claim", label: "This claim" },
                      { id: 2, node_type: "person", label: "Peter Sharpe" },
                      {
                        id: 3,
                        node_type: "ContactEmail",
                        label: "flipper23@hotmail.com",
                        outerCircle: "#2196F3"
                      },
                      { id: 4, node_type: "person", label: "Laura Matherson" },
                      { id: 5, node_type: "claim", label: "66543 987" },
                      { id: 6, node_type: "claim", label: "66543 987" },
                      { id: 7, node_type: "claim", label: "97651 876" },
                      { id: 8, node_type: "person", label: "Nigel Bonham" }
                    ],

                    links: [
                      { source: 1, target: 2 },
                      { source: 2, target: 3 },
                      { source: 3, target: 4 },
                      { source: 4, target: 5 },
                      { source: 3, target: 6 },
                      { source: 3, target: 7 },
                      { source: 6, target: 8 },
                      { source: 7, target: 8 }
                    ]
                  }
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Having the same contact info occur in multiple claims and/or policies increases 80% risk of ghost brokering.`
                }
              }
            ]
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>Reporter</span> of the claim is <span class='font-weight-black'>unrelated to the claimant</span>",
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  headers: ["Reporter", "Claimant"],
                  items: [
                    { text: "name", value: "William la Nageoire" },
                    { text: "name", value: "Peter Sharpe" },

                    { text: "hometown", value: "Quebec, Quebec" },
                    { text: "hometown", value: "Quebec, Quebec" },

                    { text: "date of birth", value: "12-12-1986" },
                    { text: "date of birth", value: "12-07-1956" }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
