<template>
  <div class="wrapper new-app">
    <ClaimDetailsViewer :header="header" />
  </div>
</template>

<script>
import ClaimDetailsViewer from "@/components2/Viewers/ClaimDetailsViewer";

export default {
  components: {
    ClaimDetailsViewer
  },

  computed: {
    header() {
      return this.$store.state.claims2[0].header;
    }
  }
};
</script>

<style lang="scss">
body {
  background-color: #f1f3f4;
  color: #262626;
}

.wrapper.new-app {
  max-width: 1440px;
  width: 100%;
  font-size: 14px;
}

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item,
.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before,
.v-application--is-ltr
  .v-list.v-sheet--shaped
  .v-list-item
  > .v-ripple__container {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
</style>
