<template>
  <div class="wrapper new-app mt-4">
    <ClaimDetailsViewer />
  </div>
</template>

<script>
import ClaimDetailsViewer from "@/components3/Viewers/ClaimDetailsViewer";

export default {
  components: {
    ClaimDetailsViewer
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  font-size: 14px;
}
</style>
