<template>
  <v-card color="white" flat>
    <v-container class="mt-4">
      <v-row class="pa-5">
        <img :src="require('@/assets/img/timeline.png')" style="width: 100%" />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import timelineImg from "@/assets/img/timeline.png";
export default {};
</script>

<style lang="scss" scoped></style>
