<template>
  <div class="menu-wrapper">
    <div class="menu d-flex flex-column">
      <div class="menu-top">
        <div class="d-flex align-center px-5 py-2">
          <v-btn icon @click.stop="$emit('drawer-left-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <img
            alt="FRISS Logo"
            src="@/assets/svg/friss-logo-full-color.svg"
            height="40px"
          />
        </div>
        <v-list class="mt-3 pa-3">
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              class="pl-2"
              :href="item.url"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon" color="#4d4c4c"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.text"
                  class="font-color-dark font-weight-bold"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="menu-bottom pa-6 font-color-light font-12">
        <div class="font-weight-bold">Version</div>
        <div>2021.34.1.226788</div>
        <div>July 7, 2021 08:45 AM GMT+1</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null,
      items: [
        {
          text: "Screening Center",
          icon: "mdi-checkbox-marked-outline",
          url: "https://frissuxdemo.dev.frisscloud.com/home/claim/"
        },
        {
          text: "Network Viewer",
          icon: "mdi-family-tree",
          url: "https://devconnect.demo.frisscloud.com/network"
        },
        {
          text: "Investigation",
          icon: "mdi-file-search-outline",
          url: "https://facts-test.test.frissplatform.eu/"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.menu,
.menu-wrapper {
  height: 100%;
}
.v-list-item-group .v-list-item--active {
  font-weight: bold;
  margin: 0 10px;
  padding: 0 10px;
}
.menu-bottom {
  margin-top: auto;
}
</style>
