<template>
  <v-sheet color="rgb(245,247,247)">
    <TopNavBar
      @drawer-left-click="drawerLeft = !drawerLeft"
      @drawer-right-click="drawerRight = !drawerRight"
      @drawer-top-click="drawerTop = !drawerTop"
    />

    <v-navigation-drawer v-model="drawerLeft" absolute temporary>
      <v-card flat class="pa-2">left</v-card>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawerRight" absolute temporary right>
      <v-card flat class="pa-2">right</v-card>
    </v-navigation-drawer>

    <v-container class="fluid justify-center main-wrapper">
      <router-view></router-view>
    </v-container>

    <v-navigation-drawer
      v-model="drawerTop"
      absolute
      temporary
      class="navigation-drawer-top"
    >
      <v-card flat class="pa-2">top</v-card>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import TopNavBar from "@/components3/TopNavBar";
export default {
  components: {
    TopNavBar
  },

  data() {
    return {
      show: false,
      overlay: false,
      drawerRight: false,
      drawerLeft: false,
      drawerTop: false
    };
  },

  methods: {
    close() {
      if (this.overlay) {
        this.overlay = !this.overlay;
      }
    }
  }
};
</script>

<style>
.main-wrapper {
  min-height: calc(100vh - 66px);
}

.search-container {
  position: absolute;
  top: 64px;
  width: 100%;
  z-index: 2;
  background-color: blue;
  color: white;
}

.base {
  height: calc(100vh - 66px);
}

.v-navigation-drawer.navigation-drawer-top {
  height: 300px !important;
  width: 100% !important;
  transform: translate(0%, -100%) !important;
}

.v-navigation-drawer--open.navigation-drawer-top {
  transform: translateY(0) !important;
}

.container.fluid {
  max-width: 1392px !important;
  width: 100% !important;
}

.header-container > .container {
  max-width: 1392px !important;
}

.container.fluid .wrapper {
  width: 100% !important;
}
</style>
