<template>
  <v-row class="mt-5">
    <v-col cols="12">
      <v-list class="pb-9 party-exposures">
        <div
          class="
            px-1
            py-3
            mx-4
            font-16 font-color-dark font-weight-bold
            party-exposures-title
          "
        >
          {{ partyExposures.length }}
          {{ partyExposuresText }}
        </div>
        <v-list-item-group color="primary" class="px-4">
          <v-list-item
            v-for="(partyExposure, index) in partyExposures"
            :key="index"
            class="partyExposure-item"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex justify-space-between">
                <div class="d-flex justify-space-between">
                  <div
                    :class="`mr-4 ${colorOfScoringClassBg(
                      partyExposure.score
                    )} party-exposure-score`"
                  >
                    <span>{{ partyExposure.score }}</span>
                  </div>
                  <div
                    class="
                      partyExposure-name
                      font-16 font-color-dark font-weight-bold
                    "
                  >
                    {{ partyExposure.title }}
                    <span class="font-weight-regular"
                      >- {{ partyExposure.subtitle }} -
                      {{ partyExposure.cost }}</span
                    >
                  </div>
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["partyExposures"],

  computed: {
    partyExposuresText() {
      return `Exposure${
        this.partyExposures?.length > 1 ? "s" : ""
      } in this claim`;
    }
  }
};
</script>

<style>
.party-exposures {
  border-radius: 6px !important;
}
.partyExposure-item,
.party-exposures-title {
  border-bottom: 1px solid #e9e9e9;
}
.partyExposure-name {
  min-width: 280px;
}
.partyExposure-description {
  width: 100%;
}
.party-exposure-score {
  width: 45px;
  height: 24px;
}
</style>
