<template>
  <v-row class="pa-1">
    <v-col cols="7">
      <v-row>
        <v-col cols="5">
          <div class="font-weight-bold font-16 font-color-dark mb-5">
            Personal details
          </div>
          <div class="party-img-wrapper">
            <img :src="party.image" class="party-img" />
            <div class="font-12 font-color-light text-center pt-3">
              Source:
              <span class="text-decoration-underline image-source">{{
                party.image_source
              }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="7" class="mt-10">
          <div class="pb-5">
            <div class="font-12 font-color-light">Name</div>
            <div class="font-weight-bold font-color-dark">
              {{ party.name }}
            </div>
          </div>
          <div class="pb-5">
            <div class="font-12 font-color-light">Type</div>
            <div class="font-weight-bold font-color-dark">
              {{ party.type }}
            </div>
          </div>
          <div class="pb-5">
            <div class="font-12 font-color-light">Contact prohibited?</div>
            <div class="font-weight-bold font-color-dark">
              {{ prohibitedContact(party.contact) }}
            </div>
          </div>
          <div class="pb-5">
            <div class="font-12 font-color-light">Primary phone</div>
            <div class="font-weight-bold font-color-dark">
              {{ party.phone }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <div class="pb-5">
        <div class="font-12 font-color-light">Home address</div>
        <div class="font-weight-bold font-color-dark">
          {{ party.address }}
          {{ party.position }}
        </div>
      </div>
      <div class="party-map">
        <GoogleMap
          :address="party.address"
          :coordinates="party.coordinates"
          :phone="party.phone"
          :index="index"
          size="small"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import GoogleMap from "../GoogleMap.vue";

export default {
  props: ["party", "index"],

  components: {
    GoogleMap
  },

  methods: {
    prohibitedContact(val) {
      return val === true ? "No" : "Yes";
    }
  }
};
</script>

<style lang="scss">
.party-img-wrapper {
  width: 148px;
}
.party-img {
  border-radius: 50%;
  width: 148px;
  height: 148px;
  object-fit: cover;
}
.image-source {
  &:hover {
    cursor: pointer;
  }
}
</style>
