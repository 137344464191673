<template>
  <div>
    <div class="overline grey--text">{{ header }} ({{ items.length }})</div>

    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      @click="$bus.emit('scroll-to', `exposure-id-${item.exposureId}`)"
    >
      <v-list-item-action
        ><span
          class="text-overline"
          :class="item.score > 0 ? 'red--text' : 'green--text'"
          >{{ item.score }}</span
        ></v-list-item-action
      >
      <v-list-item-content>
        <div class="item-header">{{ item.header }}</div>
        <div class="grey--text">{{ item.footer }}</div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ["items", "header"],
};
</script>

<style lang="scss" scoped>
.item-header {
  color: black;
  font-weight: 400;
}
</style>