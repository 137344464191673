<template>
  <div>
    <overlay-scrollbars
      class="scrollbar-wrapper"
      :style="{ height: height + 'px' }"
      :options="{
        className: 'os-theme-thin-dark',
        scrollbars: { autoHide: 'never' }
      }"
      :ref="name"
    >
      <slot></slot>
    </overlay-scrollbars>
  </div>
</template>

<script>
export default {
  props: ["name", "height"],

  data() {
    return {};
  },

  mounted() {
    this.ts = this.$refs[this.name].osInstance();

    this.$bus.on("scroll-to", this.goto);
  },

  methods: {
    goto(id, duration = 400) {
      const el = document.querySelector("#" + id);

      this.ts.scroll(
        { el, margin: [10, 10, 10, 10] },

        duration,
        "easeInOutCubic",
        () => {}
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.scrollbar-wrapper {
  padding: 0 15px 0 0;
}
</style>
