<template>
  <div>
    <div
      v-for="(vehicle, index) in vehicles"
      :key="index"
      @click="selectVehicle(index)"
      :class="`vehicle-side-item pa-4 mb-2 d-flex align-center justify-space-between ${
        activeVehicle === index && 'active-vehicle'
      }`"
    >
      <div>
        <div class="font-color-dark font-weight-bold vehicle-brand">
          {{ vehicle.brand }}
        </div>
        <div class="font-12 font-color-light">{{ vehicle.plate }}</div>
      </div>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vehicles"],

  data() {
    return {
      activeVehicle: 0
    };
  },

  methods: {
    selectVehicle(index) {
      this.activeVehicle = index;
    }
  },

  watch: {
    activeVehicle: function (newVal) {
      this.$emit("selectedVehicle", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.vehicle-side-item {
  border-radius: 6px;
  box-shadow: none;
  min-height: 73px;
  &:hover {
    cursor: pointer;
  }

  .vehicle-brand {
    opacity: 0.7;
  }
}
.active-vehicle {
  background-color: #fff;

  .vehicle-brand {
    opacity: 1;
  }
}
</style>
