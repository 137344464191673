<template>
  <v-row class="mt-4">
    <v-col cols="3">
      <PartiesSide :parties="data" @selectedParty="setSelectedParty" />
    </v-col>
    <v-col cols="9">
      <v-card
        class="mb-4 elevation-0 party-card"
        v-for="(party, index) in data"
        :key="index"
        v-show="selectedParty === index"
        id="#observer-root3"
      >
        <CardWithFooter colorTop="white" colorBottom="#BADDFF">
          <template #top>
            <PartyDetailTop :party="party" :index="index" />
          </template>
          <template #bottom>
            <PartyDetailBottom :party="party" />
          </template>
        </CardWithFooter>
        <PartyExposures :partyExposures="party.exposures" />
        <div>
          <img
            :src="party.history_claim"
            style="width: 100%; margin-top: 40px"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import CardWithFooter from "@/components2/CardWithFooter.vue";
import PartiesSide from "@/components2/parties/PartiesSide.vue";
import PartyDetailTop from "@/components2/parties/PartyDetailTop.vue";
import PartyDetailBottom from "@/components2/parties/PartyDetailBottom.vue";
import PartyExposures from "@/components2/parties/PartyExposures.vue";

export default {
  components: {
    CardWithFooter,
    PartyDetailTop,
    PartyDetailBottom,
    PartiesSide,
    PartyExposures
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      selectedParty: 0
    };
  },

  methods: {
    setSelectedParty(val) {
      this.selectedParty = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.party-card {
  background-color: transparent;
}
</style>
