<template>
  <div>
    <v-list-item
      v-ripple="false"
      v-for="(item, i) in items"
      :key="i"
      @click="$bus.emit('scroll-to', `exposure-id-${item.exposureId}`)"
    >
      <v-list-item-action class="mr-5">
        <div :class="colorOfScoringClassBg(item.score)">
          <span>{{ guidewireScore(item) }}</span>
        </div>
      </v-list-item-action>
      <v-list-item-content>
        <div class="d-flex justify-space-between">
          <div>
            <div class="item-header">{{ item.header }}</div>
            <div class="grey-text">{{ item.footer }}</div>
          </div>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: ["items", "header"],

  methods: {
    guidewireScore(item) {
      const score = this.$route.query.score;
      return score && item.exposureId === 1
        ? score - 50
        : score && item.exposureId === 2
        ? 50
        : item.score;
    }
  }
};
</script>

<style lang="scss" scoped>
.item-header {
  color: black;
  font-weight: bold;
  padding-bottom: 4px;
}

.v-list-item {
  background-color: transparent;
  min-height: 71px;
  border-radius: 6px;
  margin-bottom: 8px;

  .item-header {
    color: #262626;
  }

  .item-header,
  .grey-text {
    opacity: 0.7;
  }

  .v-icon {
    color: #8f8f8f;
  }
}

.v-list-item--active {
  background-color: #fff;

  .item-header,
  .grey-text {
    opacity: 1;
  }

  .v-icon {
    color: #262626;
  }
}

.theme--light.v-list-item:hover::before {
  background: #c3c3c3;
}

.v-list-item--active:hover::before,
.v-list-item--active::before,
.v-list-item:hover::before {
  opacity: 0;
}

.pad-left {
  padding-left: 2px;
}

.red-bg {
  background-color: #ee0000;
}
.green-bg {
  background-color: #24b324;
}
.yellow-bg {
  background-color: #ffcc00;
}
.grey-text {
  color: #8f8f8f;
}
</style>
