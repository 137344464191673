<template>
  <!-- see https://css-tricks.com/fluid-width-video/ -->
  <div style="padding: 56.25% 0 0 0; position: relative">
    <iframe
      src="https://player.vimeo.com/video/304790496?title=0&amp;byline=0&amp;portrait=0&amp;muted=1&amp;autoplay=1&amp;dnt=1&amp;loop=1&amp;background=1&amp;quality=1080p&amp;app_id=122963"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen=""
      style="
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        min-width: 100%;
      "
      title="Welcome to FRISS - Website Background"
      data-ready="true"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>