<template>
  <div class="visualizer">
    <v-btn x-small @click="fit()">fit</v-btn>
    <div id="mygraph2d"></div>
  </div>
</template>

<script>
import ForceGraph from "force-graph";

export default {
  name: "Visualizer2",
  components: {},
  mounted() {
    const df = {
      nodes: [
        { id: 1, color: "#FF9800" },
        { id: 2, color: "#009688" },
        { id: 3, color: "#9E9E9E" },
        { id: 4, color: "#009688" },
        { id: 5, color: "#FF9800" },
        { id: 6, color: "#009688" },
        { id: 7, color: "#FF9800" }
      ],

      links: [
        { source: 1, target: 2 },
        { source: 2, target: 3 },
        { source: 2, target: 4 },
        { source: 3, target: 4 },
        { source: 4, target: 5 },
        { source: 5, target: 6 },
        { source: 6, target: 7 }
      ]
    };

    const el = document.getElementById("mygraph2d");

    this.graph = ForceGraph()(el);

    this.graph
      .height(400)
      .width(1200)
      .linkDirectionalParticles(1)
      .graphData(df);

    setTimeout(() => {
      this.graph.zoomToFit(250, 20);
    }, 1500);
  },

  methods: {
    fit() {
      this.graph.zoomToFit(250, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.visualizer {
  height: 500px;
  width: 100%;
}
</style>
