<template>
  <div>
    <div
      v-for="(party, index) in parties"
      :key="index"
      @click="selectParty(index)"
      :class="`party-side-item pa-4 mb-2 d-flex align-center justify-space-between ${
        activeParty === index && 'active-party'
      }`"
    >
      <div>
        <div class="font-color-dark font-weight-bold party-name">
          {{ party.name }}
        </div>
        <div class="font-12 font-color-light">{{ party.description }}</div>
      </div>
      <v-icon>mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ["parties"],

  data() {
    return {
      activeParty: 0
    };
  },

  methods: {
    selectParty(index) {
      this.activeParty = index;
    }
  },

  watch: {
    activeParty: function (newVal) {
      this.$emit("selectedParty", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.party-side-item {
  border-radius: 6px;
  box-shadow: none;
  min-height: 73px;
  &:hover {
    cursor: pointer;
  }

  .party-name {
    opacity: 0.7;
  }
}
.active-party {
  background-color: #fff;

  .party-name {
    opacity: 1;
  }
}
</style>
