<template>
  <div>
    <v-divider class="mx-0"></v-divider>

    <v-card flat class="exposure-detail pa-2">
      <div class="exposure-detail-header" @click="show = !show">
        <span
          class="d-inline-flex mr-2 text-overline score justify-center"
          :class="item.score == 0 ? 'green--text' : 'red--text'"
          >{{ item.score }}</span
        >

        <span v-html="item.text"></span>

        <v-btn icon class="close-btn" v-show="item.visualizers.length">
          <v-icon>{{ show ? "mdi-menu-up" : "mdi-menu-down" }} </v-icon>
        </v-btn>
      </div>

      <v-expand-transition>
        <div v-show="show">
          <div v-for="(visualizer, index) in item.visualizers" :key="index">
            <component
              :is="visualizer.type"
              :details="visualizer.details"
              :show="show"
            ></component>
          </div>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
// see https://vueschool.io/articles/vuejs-tutorials/lazy-loading-individual-vue-components-and-prefetching/
export default {
  props: ["item"],
  components: {
    Card1: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/Card1"),

    Card2: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/Card2"),

    ClaimDate: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/ClaimDate"),

    Card3: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/Card3"),

    PhotoGrid: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/PhotoGrid"),

    GeoMap: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/GeoMap"),

    ExposureInsight: () =>
      import(/* webpackPrefetch: true */ "@/components/ExposureInsight"),

    Placeholder: () =>
      import(
        /* webpackPrefetch: true */ "@/components/visualizers/Placeholder"
      ),

    BarChart: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/BarChart"),

    Network: () =>
      import(/* webpackPrefetch: true */ "@/components/visualizers/Network")
  },

  data() {
    return {
      show: false
    };
  },

  mounted() {
    this.$bus.on("exposure-close", this.close);
    this.$bus.on("exposure-open", this.open);
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.exposure-detail-header {
  position: relative;
}

.exposure-detail-header:hover {
  cursor: pointer;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}

.score {
  min-width: 25px;
}
</style>
