<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="interface_id"
    hide-default-footer
    class="mt-5"
  >
    <template v-slot:item.download="{ item }">
      <div v-if="item.download" @click="download" class="download-btn d-flex align-center">
        <img
          :src="require('@/assets/svg/download.svg')"
          height="15px"
          alt="download"
        />
        <span class="text-decoration-underline pl-2">Download</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {

  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    }
  },

  methods: {
    download() {
      return alert("Sup?");
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 12px;
  color: #4d4c4c;
}

.download-btn {
  padding: 5px;

  &:hover {
    cursor: pointer;
    background: #fff;
    border-radius: 6px;
    transition: all 0.3s ease;
  }
}
</style>
