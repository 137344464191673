<template>
  <div class="pa-5 ml-6 mb-5 mr-8 list-visualizer rounded-sm">
    <div class="font-weight-bold mb-5">
      {{ items.title }}
    </div>
    <ol>
      <li
        v-for="(item, index) in items.list"
        :key="index"
        class="d-flex justify-space-between"
      >
        <div class="pb-5">
          <span class="pr-3">{{ index + 1 }}.</span>{{ item.title }}
        </div>
        <v-spacer />
        <div class="percentage">{{ item.percentage }}%</div>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      Array
    }
  }
};
</script>

<style lang="scss" scoped>
.list-visualizer {
  background-color: #f5f7f7;
  border-radius: 6px;
}

.percentage {
  color: #ee0000;
}
</style>
