<template>
  <v-app v-if="$msal.isAuthenticated()">
    <TopNavBar />
    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in" :duration="{ enter: 250, leave: 250 }">
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopNavBar from "@/components/TopNavBar";

export default {
  name: "App",

  components: {
    TopNavBar
  },

  data: () => ({})
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0 !important;
}
</style>
