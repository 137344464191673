<template>
  <div :id="mapName" class="google-map" :style="`${mapStyle}`"></div>
</template>

<script>
import markerImg from "@/assets/img/friss-marker.png";
import houseIcon from "@/assets/svg/icon-types/house.svg";
import accidentIcon from "@/assets/svg/icon-types/accident.svg";

export default {
  props: [
    "address",
    "addresses",
    "coordinates",
    "index",
    "name",
    "phone",
    "size"
  ],

  data: function () {
    return {
      mapName: `${this.name || "small"}-map${this.index || ""}`,
      map: null,
      bounds: null,
      markers: []
    };
  },

  mounted: function () {
    /* eslint-disable */
    this.bounds = new google.maps.LatLngBounds();
    const element = document.getElementById(this.mapName);
    const mapCentre = this.addresses?.[0].position || this.coordinates;
    const options = {
      center: new google.maps.LatLng(mapCentre.lat, mapCentre.lng),
      zoom: 13,
      mapTypeControl: false,
      streetViewControl: false
    };

    this.map = new google.maps.Map(element, options);

    if (this.size === "big") {
      // Create position
      this.addresses.forEach((adr) => {
        const position = new google.maps.LatLng(
          adr.position.lat,
          adr.position.lng
        );

        // Create infowindow
        const infowindow = new google.maps.InfoWindow({
          content: this.setInfoWindowTemplate(adr)
        });

        infowindow.setPosition(adr.position);
        infowindow.open(this.map);
        this.hideMarkers(this.markers);

        this.map.fitBounds(this.bounds.extend(position));
      });
    } else {
      // Create scaled marker icon
      const icon = {
        url: markerImg,
        scaledSize: new google.maps.Size(32, 32)
      };

      // Create marker
      let marker = new google.maps.Marker({
        position: this.coordinates,
        map: this.map,
        icon
      });
      this.markers.push(marker);

      // Create infowindow
      const infowindow = new google.maps.InfoWindow({
        content: this.setInfoWindowTemplate(this.address, this.phone)
      });

      // Open infoWindow on marker click
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: true
        });
      });
    }
  },

  methods: {
    setInfoWindowTemplate(adr, phone) {
      return this.size === "big"
        ? `<div style="display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 19px;
        }">
      <img src="${
        adr.type === "house" ? houseIcon : accidentIcon
      }" style="height: 19px; padding-right: 12px" />
      <div style="font-family: 'Open Sans', sans-serif;
  font-size: 14px;">${adr.name}
  <div style="font-weight: bold; font-size: 12px">${adr.personType}</div>
  </div>
      </div>
      `
        : `<div style="font-weight: bold;">Address:</div>
        <div style="font-family: 'Open Sans', sans-serif;
  font-size: 14px;">${adr}</div>
        <br/>
        <div style="font-weight: bold;">Phone:</div>
        <div style="font-family: 'Open Sans', sans-serif;
  font-size: 14px;">${phone}</div>`;
    },

    hideMarkers(markers) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
    }
  },

  computed: {
    mapStyle() {
      return this.size === "big" ? "height: 515px" : "height: 215px";
    }
  }
};
</script>

<style lang="scss">
.google-map {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  background: rgb(235, 235, 235);
}

#locations-map {
  .gm-ui-hover-effect {
    display: none !important;
  }
}

.infoWindow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 19px;
  }
}

.infoWindow-font {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
}
</style>
