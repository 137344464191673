<template>
  <v-card tile flat height="600" color="#F1F3F4">
    <v-list shaped color="#F1F3F4" class="pa-0">
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        active-class="exposure-selected"
      >
        <!-- <div class="exposure-title mb-2">Open exposures</div> -->

        <ExposureNavListGroup
          header="suspicious"
          :items="exposuresOpenFlagged"
          v-if="exposuresOpenFlagged.length"
        />

        <ExposureNavListGroup
          header="not suspicious"
          :items="exposuresOpenNotFlagged"
          v-if="exposuresOpenNotFlagged.length"
        />

        <!-- <div class="exposure-title mb-2 mt-10">Closed exposures</div> -->

        <ExposureNavListGroup
          header="not suspicious"
          :items="exposuresClosedNotFlagged"
          v-if="exposuresClosedNotFlagged.length"
        />
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import ExposureNavListGroup from "@/components2/exposures/ExposureNavListGroup";

export default {
  components: { ExposureNavListGroup },
  props: ["exposures"],
  data() {
    return {
      selectedItem: 0
    };
  },

  mounted() {
    const callback = (entries) => console.log("observable", entries);
    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("#observer-root"),
      threshold: 1.0
    });

    document
      .querySelectorAll(".observable")
      .forEach((el) => observer.observe(el));
  },

  computed: {
    exposuresOpenFlagged() {
      return this.exposures && this.exposures.length
        ? this.exposures.filter((d) => d.status == "open" && d.flagged)
        : [];
    },

    exposuresOpenNotFlagged() {
      return this.exposures && this.exposures.length
        ? this.exposures.filter((d) => d.status == "open" && !d.flagged)
        : [];
    },

    exposuresClosedNotFlagged() {
      return this.exposures && this.exposures.length
        ? this.exposures.filter((d) => d.status == "closed" && !d.flagged)
        : [];
    }
  },

  watch: {
    selectedItem: function (newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("selectedExposure", newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
.exposure-title {
  color: black;
  font-weight: 700;
}
</style>
