import carImage from "@/assets/img/image_14.png";
import overviewImage from "@/assets/img/car-overview.png";
import image1 from "@/assets/img/jeep-1.png";
import image2 from "@/assets/img/jeep-2.png";
import image3 from "@/assets/img/jeep-2.png";
import image4 from "@/assets/img/jeep-2.png";
import personImage from "@/assets/img/person.png";

export const claims = [
  {
    nav: [
      {
        exposureId: 1,
        score: 350,
        header: "Robin Sparkles",
        footer: "1st party vehicle total loss",
        flagged: true,
        status: "open"
      }
    ],

    header: {
      claimId: "0076-87-876",
      type: "One-sided collision",
      status: "open",
      deadline: "12 days",
      lossDate: "21-02-2021 (04:21 PM)",
      insured: "Robin Sparkles",
      policyId: "987138761",
      location: "Saint-Raymond, Quebec",
      photoCount: 4,
      amountClaimed: "$ 31,900",
      amountAtRisk: "$ 31,900",
      screeningId: "88762349898",
      screeningDate: "23-02-2021",
      frissScore: 440,
      assignedTo: [
        {
          image: "https://randomuser.me/api/portraits/women/65.jpg",
          name: "Maria Fernandez"
        },
        {
          image: personImage,
          name: "Olivia Pierce"
        }
      ],
      screening: "1 out of 8"
    },

    screening: {
      header: "85% chance of a staged accident",
      risk: "high risk",
      color: "red"
    },

    vehicles: [
      {
        brand: "Jeep Wrangler Sport",
        vin: "1C4AJWBG5HL533650",
        year: "2016",
        image: carImage,
        overview: overviewImage,
        askFor: ["purchase date", "purchase price", "purchase location"],
        plate: "IKT3475",
        passengers: [
          {
            fullName: "Kenneth Hargreaves",
            description:
              "Driver of insured’s vehicle (not insured or household member)"
          },
          { fullName: "Chris Matherson", description: "Passenger" },
          {
            fullName: "Ramona Wilde",
            description: "Passenger & owner of insured vehicle"
          }
        ]
      }
    ],

    addresses: [
      {
        position: { lat: 52.090736, lng: 5.12142 },
        type: "accident",
        name: "Ramona Wilde",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      },
      {
        position: { lat: 52.290736, lng: 5.22142 },
        type: "house",
        name: "Chris Matherson",
        infoWindow: {
          position: { lat: 0, lng: 0 },
          open: false,
          template: ""
        }
      }
    ],

    images: [
      {
        path: image1,
        name: "jeep-1.png",
        providedBy: "Kenneth Hargreaves",
        providerType: "Claimant",
        irregularities: [
          {
            title:
              "Photo appears to have been taken 2 months prior to claim date",
            details: "More details here"
          },
          {
            title: "Photo appears in an earlier claim",
            details: "More details here"
          }
        ]
      },
      {
        path: image2,
        name: "jeep-2.png",
        providedBy: "Kenneth Hargreaves",
        providerType: "Claimant",
        irregularities: [
          {
            title:
              "Photo appears to have been taken 2 months prior to claim date",
            details: "More details here"
          },
          {
            title: "Photo appears in an earlier claim",
            details: "More details here"
          }
        ]
      },
      {
        path: image3,
        name: "jeep-3.png",
        providedBy: "Kenneth Hargreaves",
        providerType: "Claimant",
        irregularities: [
          {
            title:
              "Photo appears to have been taken 2 months prior to claim date",
            details: "More details here"
          },
          {
            title: "Photo appears in an earlier claim",
            details: "More details here"
          }
        ]
      },
      {
        path: image4,
        name: "jeep-4.png",
        providedBy: "Kenneth Hargreaves",
        providerType: "Claimant",
        irregularities: [
          {
            title:
              "Photo appears to have been taken 2 months prior to claim date",
            details: "More details here"
          },
          {
            title: "Photo appears in an earlier claim",
            details: "More details here"
          }
        ]
      }
    ],

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 350,
          scoreColor: "red",
          header: "Robin Sparkles - 1st party vehicle total loss",
          coverage: "Collision coverage",
          amountClaimed: "$ 31,900",
          amountAtRisk: "$ 31,900",
          subHeader: "2017 Jeep Wrangler Sport",
          status: "not reviewed",
          suggestion: "send to SIU",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 100,
            text: `Claim history indicates an <span class="font-weight-black"> earlier total loss claim with the same VIN</span>.`,
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  items: [
                    { text: "claim", value: "0076-34-887 / 12-12-2020" },
                    { text: "claimed", value: "$ 33,600" },
                    {
                      text: "status",
                      value: "Closed - Accepted",
                      bold: true
                    },
                    { text: "claimant", value: "Didier le Fevre" },
                    { text: "vin", value: "1C4AJWBG5HL533650" }
                  ],
                  footer: "Go to claim"
                }
              }
            ]
          },

          {
            score: 100,
            text: `<span class="font-weight-black">Image screening irregularities</span> found (4/4)`,
            visualizers: [{ type: "PhotoGrid", data: { id: 2 } }]
          },

          {
            score: 100,
            text: ` Same vehicle was found damaged on an <span class="font-weight-black">online market place</span>`,
            visualizers: [{ type: "Card2", data: { id: 3 } }]
          },

          {
            score: 50,
            text: `The location of the claim is in a <span class="font-weight-black">remote area</span>.`,
            visualizers: [
              {
                type: "GeoMap",
                details: {
                  coordinates: [46.8815833, -71.8513276],
                  zoom: 14,
                  height: 400,
                  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                  markers: [
                    {
                      id: "1",
                      text: "The location of the claim is in a remote area.",
                      coordinates: [46.8815833, -71.8513276]
                    }
                  ]
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Car damage fraud is 23% more likely in remote areas due to possible lack of witnesses.`
                }
              }
            ]
          }

          // {
          //     score: 50,
          //     text: `Accident <span class="font-weight-black">happened in Ontario</span>, while the <span class="font-weight-black">injured person lives in Quebec</span>.`,
          //     visualizers: [
          //         {
          //             type: "GeoMap",
          //             details: {
          //                 coordinates: [46.829853, -71.254028],
          //                 zoom: 9,
          //                 url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          //                 markers: [
          //                     {
          //                         id: "1",
          //                         text: `Accident <span class="font-weight-black">happened in Ontario</span>, while the <span class="font-weight-black">injured person lives in Quebec</span>`,
          //                         coordinates: [46.829853, -71.254028],
          //                     },
          //                 ],
          //             },
          //         },
          //         {
          //             type: "ExposureInsight",
          //             details: {
          //                 text: `Injury claims have a 20% higher pay-out in Quebec compared to Ontario.`,
          //             },
          //         },
          //     ],
          // },

          // {
          //     score: 100,
          //     text: `<span class="font-weight-black">Earlier injury claim with the same person</span>.`,
          //     visualizers: [
          //         {
          //             type: "Card1",
          //             details: {
          //                 id: "1",
          //                 items: [
          //                     { text: "claim", value: "0076-12-994 / 06-07-2020" },
          //                     { text: "claimed", value: "$ 40,000" },
          //                     {
          //                         text: "status",
          //                         value: "Closed - Accepted",
          //                         bold: true,
          //                     },
          //                     { text: "claimant", value: "Kenneth Hargreaves" },
          //                     { text: "injury", value: "Whiplash" },
          //                 ],
          //                 footer: "Go to claim",
          //             },
          //         },
          //     ],
          // },
        ]
      }
    ]
  },

  {
    nav: [
      {
        exposureId: 1,
        score: 400,
        header: "Kenneth Hargreaves",
        footer: "1st party accident benefits",
        flagged: true,
        status: "open"
      },

      {
        exposureId: 2,
        score: 40,
        header: "Chris Matherson",
        footer: "1st party accident benefits",
        flagged: false,
        status: "open"
      },

      {
        exposureId: 3,
        score: 0,
        header: "Ramona Wilde",
        footer: "1st party accident benefits",
        flagged: false,
        status: "closed"
      },

      {
        exposureId: 4,
        score: 0,
        header: "Ramona Wilde",
        footer: "1st party vehicle",
        flagged: false,
        status: "closed"
      }
    ],

    header: {
      claimId: "0076-99-143",
      type: "Accident benefits due to head-to-head collision",
      status: "New",
      deadline: "2 days",
      lossDate: "11-02-2021 (11:45 AM)",
      insured: "Ramona Wilde",
      policyId: "98553916",
      location: "Englehart, Ontario",
      photoCount: 0,
      amountClaimed: "$ 75,000",
      amountAtRisk: "$ 45,900",
      screeningId: "88711684765",
      screeningDate: "13-02-2021"
    },

    screening: {
      header: "90% chance of pre-existing injuries",
      risk: "high risk",
      color: "red"
    },

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 400,
          scoreColor: "red",
          header: "Kenneth Hargreaves",
          coverage: "3rd Party Bodily Injury",
          amountClaimed: "$ 50,000",
          amountAtRisk: "$ 40,000",
          subHeader: "Whiplash",
          status: "At SIU",
          suggestion: "nudge SIU",
          suggestionColor: "blue",
          suspicion: "90% chance of earlier injury"
        },

        items: [
          {
            score: 100,
            text: `<span class="font-weight-black">Earlier injury claim with the same person</span>`,
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  items: [
                    { text: "claim", value: "0076-12-994 / 06-07-2020" },
                    { text: "claimed", value: "$ 40,000" },
                    {
                      text: "status",
                      value: "Closed - Accepted",
                      bold: true
                    },
                    { text: "claimant", value: "Kenneth Hargreaves" },
                    { text: "injury", value: "Whiplash" }
                  ],
                  footer: "Go to claim"
                }
              }
            ]
          },

          {
            score: 100,
            text: `Injury exposure has a <span class="font-weight-black">substantially higher reserve</span> than the other injury exposures in the claim`,
            visualizers: [
              {
                type: "BarChart",
                details: {
                  data: [
                    {
                      id: 0,
                      year: "K. Hargreaves",
                      value: 50000,
                      fill: "#EE0000",
                      labelTop: "5x",
                      labelBottom: "(this exposure)"
                    },
                    {
                      id: 1,
                      year: "C. Matherson",
                      value: 10000,
                      fill: "#4D4C4C"
                    },
                    { id: 2, year: "R. Wilde", value: 10000, fill: "#4D4C4C" }
                  ],
                  width: 600,
                  height: 200,
                  padding: 0.8,
                  hoverColor: "rgba(0,0,0,0.5)"
                }
              }
            ]
          },

          {
            score: 100,
            text: `Injury <span class="font-weight-black">claim without assistance</span>`,
            visualizers: [
              {
                type: "Card3",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `No medical assistance has been provided, injuries from all 3 victims will be addressed at a local hospital to which they will go by themselves`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              },

              {
                type: "ExposureInsight",
                details: {
                  text: `Injury claims without assistance are 40% more likely to be fraudulent.`
                }
              }
            ]
          },

          {
            score: 50,
            text: `Vehicle has <span class="font-weight-black">airbags</span>, but were <span class="font-weight-black">not deployed</span>`,
            visualizers: [
              {
                type: "Card3",
                details: {
                  items: [
                    {
                      text: "claim statement",
                      value: `Vehicle has front-end damage, not major. Airbags have not been deployed.`,
                      bold: true
                    }
                  ],

                  footer: "Read full claim statement"
                }
              }
            ]
          },

          {
            score: 50,
            text: `Accident <span class="font-weight-black">happened in Ontario</span>, while the <span class="font-weight-black">injured person lives in Quebec</span>.`,
            visualizers: [
              {
                type: "GeoMap",
                details: {
                  coordinates: [48.11148145, -75.48036455],
                  zoom: 7,
                  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                  //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
                  markers: [
                    {
                      id: "1",
                      text: `198-132 Rue des Vingt Et Un, Chicoutimi, QC, Canada`,
                      coordinates: [48.3991247, -71.0791994]
                    },

                    {
                      id: "2",
                      text: `112 5th Ave, Englehart, ON P0J 1H0, Canada`,
                      coordinates: [47.8238382, -79.8815297]
                    }
                  ]
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Injury claims have a 20% higher pay-out in Quebec compared to Ontario.`
                }
              }
            ]
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-2",
          score: 40,
          scoreColor: "green",
          header: "Chris Matherson - 1st party accident benefits",
          coverage: "AB coverage",
          amountClaimed: "$ 10,000",
          amountAtRisk: "$ 5,000",
          subHeader: "Fractured leg",
          status: "Back from SIU",
          suggestion: "accept",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 40,
            text: `Linked to a <span class="font-weight-black">fraudulent claim in 2 steps</span>.`,
            visualizers: [
              {
                type: "Network",
                details: {
                  height: 420,
                  network: {
                    nodes: [
                      { id: 1, node_type: "claim", label: "This claim" },
                      {
                        id: 2,
                        node_type: "person",
                        label: "Chris Matherson",
                        outerCircle: "#2196F3"
                      },
                      { id: 3, node_type: "LastName", label: "Matherson" },
                      { id: 4, node_type: "person", label: "Laura Matherson" },
                      { id: 5, node_type: "claim", label: "66543 987" },
                      { id: 6, node_type: "person", label: "Paul Beagal" },
                      {
                        id: 7,
                        node_type: "claim",
                        label: "97651 876",
                        outerCircle: "#F44336"
                      }
                    ],

                    links: [
                      { source: 1, target: 2 },
                      { source: 2, target: 3 },
                      { source: 2, target: 4, color: "red" },
                      { source: 3, target: 4 },
                      { source: 4, target: 5, color: "red" },
                      { source: 5, target: 6, color: "red" },
                      { source: 6, target: 7, color: "red" }
                    ]
                  }
                }
              },

              {
                type: "ExposureInsight",
                details: {
                  text: `Being linked to a fraudulous claim in 2 steps on average has a 24% higher risk.`
                }
              }
            ]
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-3",
          score: 0,
          scoreColor: "green",
          header: "Ramona Wilde - 1st party accident benefits",
          coverage: "AB coverage",
          amountClaimed: "$ 10,000",
          amountAtRisk: "$ 0 remaining",
          subHeader: "Fractured nose",
          status: "Closed - Accepted"
        },

        items: [
          {
            score: 0,
            text: `No suspicion found`,
            visualizers: []
          }
        ]
      },

      {
        header: {
          exposureId: "exposure-id-4",
          score: 0,
          scoreColor: "green",
          header: "Ramona Wilde - 1st party vehicle",
          coverage: "Collision coverage",
          amountClaimed: "$ 5,000",
          amountAtRisk: "$ 0 remaining",
          subHeader: "2011 Toyota Camry",
          status: "Closed - Accepted"
        },

        items: [
          {
            score: 0,
            text: `No suspicion found`,
            visualizers: []
          }
        ]
      }
    ]
  },

  {
    nav: [
      {
        exposureId: 1,
        score: 200,
        header: "Peter Sharpe",
        footer: "1st party partial theft",
        flagged: true,
        status: "open"
      }
    ],

    header: {
      claimId: "0076-77-983",
      type: "Property theft from vehicle",
      status: "open",
      deadline: "5 days",
      lossDate: "11-01-2021 (05:30 AM)",
      insured: "Peter Sharpe",
      policyId: "987129871",
      location: "Saint-Raymond, Quebec",
      photoCount: 0,
      amountClaimed: "$ 5,600",
      amountAtRisk: "$ 5,600",
      screeningId: "98713409870",
      screeningDate: "18-02-2021"
    },

    screening: {
      header: "75% chance of ghost brokering",
      risk: "high risk",
      color: "red"
    },

    exposures: [
      {
        header: {
          exposureId: "exposure-id-1",
          score: 200,
          scoreColor: "red",
          header: "Peter Sharpe - 1st party partial theft",
          coverage: "Comprehensive",
          amountClaimed: "$ 5,600",
          amountAtRisk: "$ 5,600",
          subHeader: "Laptop, camera & smashed window",
          status: "At SIU",
          suggestion: "reject",
          suggestionColor: "blue"
        },

        items: [
          {
            score: 50,
            text: "Claim <span class='font-weight-black'>occurred during the policy's lifecycle</span>, but was <span class='font-weight-black'>reported more than 30 day after the expiry</span> of the policy",
            visualizers: [
              {
                type: "ClaimDate",
                details: {}
              }
            ]
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>> $5,000 claim</span> reported <span class='font-weight-black'>more than 30 days (35 days) after occurrence</span>",
            visualizers: []
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>Email address</span> was used in <span class='font-weight-black'>multiple claims and policies</span> with <span class='font-weight-black'>different names</span>",
            visualizers: [
              {
                type: "Network",
                details: {
                  height: 420,
                  network: {
                    nodes: [
                      { id: 1, node_type: "claim", label: "This claim" },
                      { id: 2, node_type: "person", label: "Peter Sharpe" },
                      {
                        id: 3,
                        node_type: "ContactEmail",
                        label: "flipper23@hotmail.com",
                        outerCircle: "#2196F3"
                      },
                      { id: 4, node_type: "person", label: "Laura Matherson" },
                      { id: 5, node_type: "claim", label: "66543 987" },
                      { id: 6, node_type: "claim", label: "66543 987" },
                      { id: 7, node_type: "claim", label: "97651 876" },
                      { id: 8, node_type: "person", label: "Nigel Bonham" }
                    ],

                    links: [
                      { source: 1, target: 2 },
                      { source: 2, target: 3 },
                      { source: 3, target: 4 },
                      { source: 4, target: 5 },
                      { source: 3, target: 6 },
                      { source: 3, target: 7 },
                      { source: 6, target: 8 },
                      { source: 7, target: 8 }
                    ]
                  }
                }
              },
              {
                type: "ExposureInsight",
                details: {
                  text: `Having the same contact info occur in multiple claims and/or policies increases 80% risk of ghost brokering.`
                }
              }
            ]
          },

          {
            score: 50,
            text: "<span class='font-weight-black'>Reporter</span> of the claim is <span class='font-weight-black'>unrelated to the claimant</span>",
            visualizers: [
              {
                type: "Card1",
                details: {
                  id: "1",
                  headers: ["Reporter", "Claimant"],
                  items: [
                    { text: "name", value: "William la Nageoire" },
                    { text: "name", value: "Peter Sharpe" },

                    { text: "hometown", value: "Quebec, Quebec" },
                    { text: "hometown", value: "Quebec, Quebec" },

                    { text: "date of birth", value: "12-12-1986" },
                    { text: "date of birth", value: "12-07-1956" }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
];
