<template>
  <div>
    <div
      v-if="!isIframe"
      class="
        top
        pa-2
        mt-4
        mb-7
        d-flex
        justify-center
        align-center
        header-container
      "
    >
      <v-container>
        <v-row no-gutters>
          <v-col class="col-2">
            <div class="d-flex">
              <div
                :class="`${colorOfScoringClassBg(
                  header.frissScore
                )} exposure-score mr-2`"
              >
                <span>{{ header.frissScore }}</span>
              </div>
              <div>
                <div class="item-title">FRISS Score</div>
                <div class="item-value">RED</div>
              </div>
            </div>
          </v-col>
          <v-col class="col-5 detail-col">
            <div>
              <div class="item-title">Claim Id</div>
              <div class="item-value">{{ header.claimId }}</div>
            </div>
            <div>
              <div class="item-title">Loss date</div>
              <div class="item-value">{{ header.lossDate.slice(0, 10) }}</div>
            </div>
            <div>
              <div class="item-title">Insured</div>
              <div class="item-value">{{ header.insured }}</div>
            </div>
            <div>
              <div class="item-title">Policy</div>
              <div class="item-value">{{ header.policyId }}</div>
            </div>
            <div>
              <div class="item-title">Claimed</div>
              <div class="item-value">{{ header.amountClaimed }}</div>
            </div>
          </v-col>
          <v-col class="col-5 detail-col justify-end">
            <div>
              <div class="item-title">Status</div>
              <div class="item-value">{{ header.status }}</div>
            </div>
            <div>
              <div class="item-title">Assigned to</div>
              <div class="assigned-avatars">
                <div
                  v-for="(assignee, index) in header.assignedTo"
                  :key="index"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        alt="user avatar"
                        :src="assignee.image"
                        class="avatar"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>{{ assignee.name }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div>
              <div class="item-title text-right">Screening</div>
              <div class="item-value">{{ header.screening }}</div>
            </div>
            <div class="pt-1">
              <v-btn icon disabled
                ><v-icon size="30" color="#4D4C4C"
                  >mdi-chevron-left</v-icon
                ></v-btn
              >
              <v-btn icon
                ><v-icon size="30" color="#4D4C4C"
                  >mdi-chevron-right</v-icon
                ></v-btn
              >
            </div>
            <v-btn class="pt-2" icon @click="clickNotes">
              <v-badge right color="#EE0000">
                <template v-slot:badge>
                  <span class="font-weight-bold chat-icon">2</span>
                </template>
                <img :src="header.chatIcon" alt="" />
              </v-badge>
            </v-btn>
          </v-col>
          <!--          <v-col class="col-1 detail-col align-center">-->
          <!--            <v-btn icon>-->
          <!--              <v-badge right color="#FF5875">-->
          <!--                <template v-slot:badge>-->
          <!--                  <span class="font-weight-bold chat-icon">2</span>-->
          <!--                </template>-->
          <!--                <img :src="header.chatIcon" alt="" />-->
          <!--              </v-badge>-->
          <!--            </v-btn>-->
          <!--          </v-col>-->
        </v-row>
      </v-container>
    </div>
    <!--    <div v-if="!isIframe" class="bottom d-flex justify-center align-center">-->
    <!--      <div class="py-1">-->
    <!--        <span class="font-weight-regular mr-2" style="font-size: 12px"-->
    <!--          >Suspicion</span-->
    <!--        >-->
    <!--        <span class="font-weight-black"-->
    <!--          >90% chance of pre-existing injuries</span-->
    <!--        >-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div v-if="!isIframe" class="my-4 px-5 py-2">-->
    <!--      <span class="font-weight-black"-->
    <!--        >One sided collision. Driver and 2 passengers. Driver is not the owner-->
    <!--        of the vehicle.</span-->
    <!--      >-->
    <!--      <span-->
    <!--        class="font-weight-regular pl-1 text-decoration-underline"-->
    <!--        style="color: #8f8f8f; font-size: 12px"-->
    <!--        >Read full claim statement</span-->
    <!--      >-->
    <!--    </div>-->

    <div
      v-if="isIframe"
      :class="`ma-3 ${
        isIframe && 'my-0 py-2'
      } mr-0 d-flex justify-space-between align-center`"
    >
      <img
        alt="FRISS Logo"
        src="@/assets/svg/friss-logo-full-color.svg"
        height="45px"
      />
      <!--      <div class="d-flex align-center">-->
      <!--        <img-->
      <!--          alt="FRISS Logo"-->
      <!--          src="@/assets/img/friss-score.png"-->
      <!--          height="25px"-->
      <!--        />-->
      <!--        <div-->
      <!--          :class="`ml-4 ${colorOfScoringClassText(-->
      <!--            400-->
      <!--          )} font-weight-bold d-flex align-center`"-->
      <!--          style="font-size: 30px"-->
      <!--        >-->
      <!--          {{ guidewireScore }}-->
      <!--          <v-icon v-if="guidewireScore < 50" color="#24B324" class="pl-2"-->
      <!--            >mdi-shield-check</v-icon-->
      <!--          >-->
      <!--          <v-icon v-else-if="guidewireScore < 100" color="#FFCC00" class="pl-2"-->
      <!--            >mdi-shield-half-full</v-icon-->
      <!--          >-->
      <!--          <v-icon v-else color="#EE0000" class="pl-2">mdi-shield-alert</v-icon>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              fab
              v-bind="attrs"
              v-on="on"
              class="ma-1 ext-app-link"
              color="#4d4c4c"
              href="https://facts-test.test.frissplatform.eu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              FI
            </v-btn>
          </template>
          <div class="ext-links-tooltip">
            <p>FRISS</p>
            <p>Investigations</p>
          </div>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              v-bind="attrs"
              v-on="on"
              class="ma-1 ext-app-link rounded-circle"
              color="#4d4c4c"
              href="https://frissuxdemo.dev.frisscloud.com/home/claim"
              target="_blank"
              rel="noopener noreferrer"
              @click="showTooltip = false"
            >
              FSC
            </v-btn>
          </template>
          <div class="ext-links-tooltip">
            <p>FRISS</p>
            <p>Screening</p>
            <p>Center</p>
          </div>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              outlined
              fab
              v-bind="attrs"
              v-on="on"
              class="ma-1 ext-app-link"
              color="#4d4c4c"
              href="https://devconnect.demo.frisscloud.com/network"
              target="_blank"
              rel="noopener noreferrer"
            >
              NV
            </v-btn>
          </template>
          <div class="ext-links-tooltip">
            <p>Network</p>
            <p>Viewer</p>
          </div>
        </v-tooltip>
      </div>
    </div>

    <div class="tabs">
      <v-tabs height="38" v-model="selection" background-color="transparent">
        <v-tabs-slider color="#1A8DFF"></v-tabs-slider>

        <v-tab
          v-for="(item, index) in items"
          :key="index"
          class="pl-2"
          :to="item.to"
        >
          <span style="color: #4d4c4c; font-size: 14px">
            <component
              :is="item.icon"
              :color="tabIconColor(index, selection)"
            />
            <span class="ml-1 text-capitalize">{{ item.name }}</span>
            <span v-if="item.count"> ({{ item.count }})</span>
          </span>
        </v-tab>

        <v-btn x-small color="#d47527" class="elevation-0 siu-btn">
          Send to SIU
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="selection">
        <v-tab-item v-for="item in items" :key="item.name">
          <component :is="item.component" :data="data(item.name)"></component>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div :class="feedbackClass">
      <transition name="fade">
        <div
          v-if="feedback"
          class="font-color-dark px-7 d-flex align-center justify-space-between"
          style="height: 100%"
        >
          <div class="mr-5">
            <v-btn icon class="mr-4" @click="changeFeedback">
              <v-icon color="#4d4c4c">mdi-close</v-icon>
            </v-btn>
            How would you judge this screening?
          </div>
          <div>
            <v-btn icon large>
              <v-icon color="#4d4c4c">mdi-thumb-up-outline</v-icon>
            </v-btn>
            <v-btn icon large>
              <v-icon color="#4d4c4c">mdi-thumb-down-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </transition>

      <v-btn
        v-if="!feedback"
        fab
        color="#fff"
        class="feedback-fab"
        @click="changeFeedback"
      >
        <img :src="require('@/assets/svg/thumbs.svg')" width="30px" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import ExposureViewer from "./ExposureViewer.vue";
import VehicleViewer from "./VehicleViewer.vue";
import PartyViewer from "./PartyViewer.vue";
import PhotoViewer from "./PhotoViewer.vue";
import LocationViewer from "./LocationViewer.vue";
import NetworkVisualizer from "@/components2/NetworkVisualizer.vue";
import TimelineViewer from "./TimelineViewer.vue";

// import timeline from "@/assets/svg/timeline.svg";
// import network from "@/assets/svg/network.svg";

import ExposuresIcon from "@/components2/icons/ExposuresIcon";
import CarIcon from "@/components2/icons/CarIcon";
import PersonIcon from "@/components2/icons/PersonIcon";
import CameraIcon from "@/components2/icons/CameraIcon";
import LocationIcon from "@/components2/icons/LocationIcon";
import TimelineIcon from "@/components2/icons/TimelineIcon";
import NetworkIcon from "@/components2/icons/NetworkIcon";

import eventBus from "@/eventBus";

export default {
  props: {
    header: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selection: 0,
      feedback: false,
      feedbackClass: "feedback-close",
      items: [
        {
          count: 4,
          name: "Exposures",
          icon: ExposuresIcon,
          component: ExposureViewer
        },
        {
          count: 2,
          name: "Vehicles",
          icon: CarIcon,
          component: VehicleViewer
        },
        {
          count: 4,
          name: "Parties",
          icon: PersonIcon,
          component: PartyViewer
        },
        {
          count: 2,
          name: "Photos",
          icon: CameraIcon,
          component: PhotoViewer
        },
        {
          count: 5,
          name: "Locations",
          icon: LocationIcon,
          component: LocationViewer
        },
        { name: "Timeline", icon: TimelineIcon, component: TimelineViewer },
        { name: "Network", icon: NetworkIcon, component: NetworkVisualizer }
      ]
    };
  },

  methods: {
    detailItemGridClass(itemIndex) {
      return itemIndex === 2 ? "col-5 detail-col" : "col-3 detail-col";
    },
    checkType(type) {
      return typeof type == "object";
    },
    changeFeedback() {
      this.feedback = !this.feedback;
      this.feedbackClass = this.feedback ? "feedback-open" : "feedback-close";
    },
    data(name) {
      return name === "Vehicles"
        ? this.$store.state.claims2[0].vehicles
        : name === "Locations"
        ? this.$store.state.claims2[0].addresses
        : name === "Photos"
        ? this.$store.state.claims2[0].images
        : name === "Parties"
        ? this.$store.state.claims2[0].parties
        : null;
    },
    scoringIcon(score) {
      if (score < 50) {
        return <v-icon color="green">mdi-shield-check</v-icon>;
      } else if (score < 100) {
        return <v-icon color="orange">mdi-shield-half-full</v-icon>;
      } else {
        return <v-icon color="red">mdi-shield-alert</v-icon>;
      }
    },
    tabIconColor(tabIdx, activeTabIdx) {
      return tabIdx === activeTabIdx ? "#1976d2" : "#4d4c4c";
    },
    clickNotes() {
      eventBus.$emit("drawerRight", "Discussion");
    }
  },

  computed: {
    isIframe() {
      return this.$store.state.isIframe;
    },
    guidewireScore() {
      return this.$route.query.score;
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  position: relative;
  background-color: white;
  border-radius: 6px;
  z-index: 2;
}

.header-container {
  height: 64px;
}

.pointer:hover {
  cursor: pointer;
}

.bottom {
  position: relative;
  background-color: #ee0000;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 6px;
  margin-top: -6px;
  z-index: 1;
  color: white;
}

.item-title {
  font-size: 12px;
  color: #8f8f8f;
}

.item-value {
  font-size: 14px;
  font-weight: bold;
  color: #4d4c4c;
  text-transform: capitalize;
}

.detail-col {
  display: flex;
  gap: 40px; //CAREFUL -> https://caniuse.com/?search=gap
}

.assigned-avatars {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, max-content));
  justify-content: right;
  padding-right: 10px;
}

.avatar {
  height: 22px;
  width: 22px;
  object-fit: cover;
  border: 1px solid #8f8f8f;
  border-radius: 50%;
  margin-right: -20px;
}

.ext-app-link {
  padding: 0 !important;
  min-width: 40px !important;
  height: 40px !important;
}

.ext-app-link:focus::before {
  opacity: 0 !important;
}

.ext-links-tooltip p {
  margin-bottom: 0;
  line-height: 1em;
}

.feedback-fab {
  position: fixed;
  bottom: 25px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease;
}

.feedback-open {
  position: fixed;
  bottom: 25px;
  left: 50%;
  height: 60px;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

.feedback-close {
  position: fixed;
  bottom: 0;
  left: 25px;
  width: 60px;
  z-index: 8000;
  border-radius: 50px;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-tab > span {
  color: #4d4c4d !important;
}

.v-tab--active > span {
  color: #1976d2 !important;
}

.header-score {
  margin-left: auto;
  width: 34px !important;
  height: 18px !important;
}

.siu-btn {
  position: absolute;
  right: 0;
  top: 8px;
  color: #fff;
}
</style>
