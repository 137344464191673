const capitalize = (s) => s && s.length > 0 && s[0].toUpperCase() + s.slice(1);

const reverse = (s) => s && s.split``.reverse().join``;

/**
 * Return specific class name based on scoring
 * @param {number} score
 * @returns css class name
 */
const colorOfScoringClassBg = (score) =>
  score >= 200
    ? "badge red-bg"
    : score === 0
    ? "badge green-bg"
    : score < 200
    ? "badge yellow-bg"
    : "";

/**
 * Return specific class name based on scoring
 * @param {number} score
 * @returns css class name
 */
const colorOfScoringClassText = (score) =>
  score >= 50
    ? "red-text"
    : score === 0
    ? "green-text"
    : score < 50
    ? "yellow-text"
    : "";

/**
 * Return color based on scoring to be used as actual text
 * @param {number} score
 * @returns {string} color
 */
const colorOfScore = (score) =>
  score >= 50 ? "red" : score === 0 ? "green" : score < 50 ? "yellow" : "";

const scrollerHeight = (viewportHeight) => viewportHeight - 306;

export {
  capitalize,
  colorOfScore,
  colorOfScoringClassBg,
  colorOfScoringClassText,
  reverse,
  scrollerHeight
};
