<template>
  <v-container class="mt-4">
    <v-row>
      <GoogleMap name="locations" size="big" :addresses="data" />
    </v-row>
  </v-container>
</template>

<script>
import GoogleMap from "@/components2/GoogleMap.vue";

export default {
  components: {
    GoogleMap
  },

  props: {
    data: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
