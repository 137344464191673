<template>
  <v-row class="mt-4">
    <v-col cols="9">
      <div class="exposure-content pa-4">
        <FrissScore :item="scoreItem" />
        <ExposuresList />
      </div>
    </v-col>
    <v-col cols="3">
      <Feedback :item="feedbackItem" />
    </v-col>
  </v-row>
</template>

<script>
import ExposuresList from "@/components3/exposures/ExposuresList";
import Feedback from "@/components3/Feedback";
import FrissScore from "@/components3/claims/FrissScore";

export default {
  components: {
    ExposuresList,
    Feedback,
    FrissScore
  },

  data() {
    return {
      windowHeight: window.innerHeight,
      showMap: false,
      claimId: "0076-99-143",
      scoreItem: {
        score: 400,
        status: "New",
        investigator: "John Doe",
        warnText:
          "Not all data sources were called successfully. Please contact your administrator for more details.",
        action: "Create a case for SIU"
      },
      feedbackItem: {
        text: "Feedback",
        subtext:
          ""
      }
    };
  },

  mounted() {
    this.$bus.on("claims-overview-row-click", (item) => {
      this.claimId = item.claimId;
    });
  },

  methods: {
    selectedExposure(val) {
      this.selectedExposureKey = val;
    }
  },

  computed: {
    nav() {
      if (!this.claimId) return;
      return this.claim.nav;
    },

    claim() {
      return this.$store.state.claims.filter(
        (d) => d.header.claimId === this.claimId
      )[0];
    },
    claimDetails() {
      // if (!this.claim) return;
      return this.claim.header;
    },

    screeningDetails() {
      // if (!this.claim) return;
      return this.claim.screening;
    },

    exposuresNav() {
      // if (!this.claim) return;
      return this.claim.nav;
    },

    exposures() {
      // if (!this.claim) return;
      return this.claim.exposures;
    }
  }
};
</script>

<style lang="scss" scoped>
.exposure-content {
  background-color: white;
  border-radius: 6px;
}
</style>
