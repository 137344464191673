<template>
  <v-container class="pt-0 pb-0">
    <v-row class="observable d-flex align-center mb-0 friss-score">
      <div class="d-flex col-6 pl-0 align-center">
        <div
          :class="`
        exposure-score
        d-flex
        justify-center
        align-center
        font-weight-bold '
        ${colorOfScoringClassBg(item.score)}`"
        >
          {{ item.score }}
        </div>

        <div class="ml-4 font-weight-bold font-color-dark">
          FRISS Score®
          <div :class="`text-uppercase ${colorOfScoringClassText(item.score)}`">
            {{ colorOfScore(item.score) }}
          </div>
        </div>

        <div class="ml-8">
          <div class="item-title">Status</div>
          <v-select
            :menu-props="{ contentClass: 'dark-list' }"
            :items="statusItems"
            placeholder="Select"
            height="22px"
            append-icon=""
          >
          </v-select>
        </div>
      </div>

      <div class="d-flex col-6 pr-0 justify-space-between align-center">
        <div class="investigator">
          <div class="item-title">Investigator</div>
          <v-select
            :menu-props="{ contentClass: 'dark-list' }"
            :items="investigatorItems"
            placeholder="Select"
            height="22px"
            append-icon=""
          >
          </v-select>
        </div>

        <div>
          <div class="d-flex align-center">
            <v-btn
              color="#1A8EFF"
              dark
              class="review-btn elevation-0"
              min-height="48px"
              min-width="198px"
            >
              <div class="action-item text-left font-weight-bold">
                {{ item.action }}
                <v-icon class="review-icon">mdi-chevron-right</v-icon>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
    </v-row>
    <!--    <v-row v-if="item.warnText" class="d-flex warning-text">-->
    <!--      <img alt="" :src="require('@/assets/svg/error.svg')" class="pr-3" />-->
    <!--      <span>{{ item.warnText }}</span>-->
    <!--    </v-row>-->
  </v-container>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },

  data() {
    return {
      statusItems: [
        "Discard - no follow up",
        "Discard - incorrect",
        "Open",
        "New",
        "Assign",
        "On hold"
      ],
      investigatorItems: ["Will Barrow", "Hermann P. Schnitzel", "Elon Gated"]
    };
  }
};
</script>

<style lang="scss">
.exposure-score {
  width: 65px;
  height: 36px;
  border-radius: 6px;
  font-size: 20px !important;
  user-select: none;
  color: #fff;

  .item-title {
    padding-top: 3px;
  }

  &:hover {
    cursor: pointer;
  }
}

.friss-score {
  .v-input__control > .v-input__slot:before {
    border-style: none;
  }

  .v-input__control > .v-input__slot:after {
    border-style: none;
  }
}

.v-menu__content {
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25) !important;
}

.dark-list {
  .v-list {
    background: #4d4c4c !important;
    min-width: 285px !important;
  }
}

.theme--light.v-list-item:hover::before {
  opacity: 0.1;
}

.v-list-item--link:before {
  border-radius: 6px;
}

.v-list-item__title {
  font-size: 14px !important;
}

.friss-score
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.friss-score .theme--light.v-list-item.v-list-item--highlighted::before {
  color: #fff !important;
  font-weight: bold;
  margin: 0 10px;
  padding: 0 10px;
}

.v-select {
  margin: 0;
  padding: 0;
  height: 22px;
  margin-top: -3px;
  max-width: 190px;

  & .v-icon {
    padding-top: 2px;
  }
}

.v-select__selection--comma,
.v-select.v-text-field input::placeholder {
  color: #4d4c4c;
  font-weight: bold;
  font-size: 14px;
}

.v-application .primary--text.v-list-item--highlighted {
  color: #fff !important;
}

.v-list-item--highlighted .v-list-item__content {
  padding: 12px 3px;
  font-weight: bold;
}

.v-input--is-dirty .v-select__selection--comma::after {
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: 500;
  color: #4d4c4c;
  content: "\f078";
  padding-left: 5px;
}

.v-select:not(.v-input--is-dirty)::after {
  font-family: FontAwesome;
  font-size: 14px;
  font-weight: 500;
  color: #4d4c4c;
  content: "\f078";
  padding-left: 5px;
  margin-left: -130px;
  padding-top: 2px;
}

.action-item {
  text-transform: none;
}

.warning-text {
  background-color: #4d4c4c;
  color: #fff;
  font-weight: bold;
  padding: 6px 15px;
  border-radius: 6px;
  margin-top: 0 !important;
}

.review-btn {
  letter-spacing: normal;
}
</style>
