<template>
  <splide
    :options="options"
    :slides="images"
    @splide:click="imageClicked"
    :class="`${hideArrows ? 'hideArrows' : ''}`"
  >
    <splide-slide v-for="(image, index) in images" :key="index">
      <img
        :src="image.path"
        :alt="`image-${index}`"
        width="100%"
        :class="splideImgClass(index)"
      />
      <div class="splide-image-bottom"></div>
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  components: {
    Splide,
    SplideSlide
  },

  props: {
    images: {
      type: Array
    }
  },

  data() {
    return {
      options: {
        perPage: 3,
        direction: "ttb",
        height:
          this.images.length <= 3 ? `${this.images.length * 10}rem` : "30rem",
        pagination: false,
        fixedHeight: "10rem"
      },
      activeImage: 0
    };
  },

  methods: {
    imageClicked(_, slide) {
      this.$emit("slideClicked", slide.index);
      this.activeImage = slide.index;
    },
    splideImgClass(index) {
      return this.activeImage === index ? "active" : "";
    }
  },

  computed: {
    hideArrows() {
      return this.images.length <= 3;
    }
  }
};
</script>

<style lang="scss">
.splide {
  padding: 2rem 0;

  img {
    cursor: pointer;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    opacity: 0.6;

    &.active {
      opacity: 1;
    }

    &:hover {
      -webkit-filter: brightness(0.85);
      filter: brightness(0.85);
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
}

.splide__arrow.splide__arrow--prev {
  top: -0.5em !important;
}

.splide__arrow.splide__arrow--next {
  bottom: 0.7em !important;
}

.splide__arrow {
  background: none;
  height: 2.6em;
  width: 2.6em;
  color: rgb(77, 76, 76);

  &:hover {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    background: rgba(0, 0, 0, 0.08);
    opacity: 0.7;
  }
}

.splide-image-bottom {
  height: 4px;
  background-color: #ee0000;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.hideArrows {
  padding-top: 0 !important;

  .splide__arrows {
    display: none;
  }
}
</style>
