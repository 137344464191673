<template>
  <v-app-bar flat color="white" style="z-index: 10" app>
    <v-app-bar-nav-icon
      @click.stop="$emit('drawer-left-click')"
    ></v-app-bar-nav-icon>

    <router-link to="/">
      <div class="d-flex align-center logo">
        <img
          alt="FRISS Logo"
          src="@/assets/svg/friss-logo-full-color.svg"
          height="40px"
        />
      </div>
    </router-link>

    <v-spacer></v-spacer>

    <v-text-field
      flat
      background-color="#F1F3F4"
      style="max-width: 450px"
      class="my-10 mx-4 rounded-lg"
      solo
      hide-details
      dense
      color="blue"
      placeholder="Type to search"
      append-icon="mdi-magnify"
      clearable
      @keyup.enter="overlay = true"
      @click:append="overlay = true"
    ></v-text-field>

    <v-spacer></v-spacer>

    <v-btn icon @click.stop="$emit('drawer-top-click')">
      <v-icon>mdi-bell-outline</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon @click.stop="emitDrawer">mdi-dots-vertical</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  methods: {
    emitDrawer() {
      eventBus.$emit("drawerRight");
    }
  }
};
</script>

<style lang="scss" scoped></style>
