<template>
  <div>
    <div class="observable d-flex align-start my-5" :id="item.exposureId">
      <div
        @click="handleClick"
        :class="`ml-4
        d-flex
        justify-center
        align-center
        font-weight-bold '
        ${colorOfScoringClassBg(item.score)}`"
      >
        <span>{{ guidewireScore(item) }}</span>
      </div>

      <div class="ml-4">
        <div class="text-h6 font-color-dark font-weight-bold">
          {{ item.header }}
        </div>
        <div class="text-body-2 font-color-dark">
          {{ item.coverage }} - {{ item.subHeader }} - {{ item.amountClaimed }}
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="mr-4">
        <div class="d-flex justify-end align-center">
          <v-avatar size="44px" class="img-assigned mr-4">
            <img alt="person" :src="require('@/assets/img/person.png')" />
          </v-avatar>

          <v-btn
            color="#1A8EFF"
            dark
            class="review-btn elevation-0"
            min-height="42px"
            min-width="250px"
          >
            <div class="status text-left">Status</div>
            <div class="status-subtext text-left font-weight-bold">
              {{ item.status
              }}<v-icon class="review-icon">mdi-chevron-down</v-icon>
            </div>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="px-4 mb-4">
      <Suspicion :suspicion="item.suspicion" />
    </div>

    <!--    <div class="px-5 py-3">-->
    <!--      <v-tabs height="38" background-color="transparent">-->
    <!--        <v-tabs-slider color="#1A8DFF"></v-tabs-slider>-->

    <!--        <v-tab v-for="(item, index) in items" :key="index" class="pl-2">-->
    <!--          <span style="color: #8f8f8f; font-size: 14px">-->
    <!--            <span class="ml-1 text-capitalize">{{ item.title }}</span>-->
    <!--          </span>-->
    <!--        </v-tab>-->
    <!--      </v-tabs>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Suspicion from "@/components2/exposures/Suspicion";

export default {
  components: { Suspicion },
  props: ["item"],

  data() {
    return {
      open: false,
      items: [
        { title: "Indicators" },
        { title: "Exposure details" },
        { title: "Claimant details" },
        { title: "Injury details" }
      ]
    };
  },

  methods: {
    handleClick() {
      this.open = !this.open;

      this.$bus.emit(this.open ? "exposure-open" : "exposure-close");
    },
    guidewireScore(item) {
      const score = this.$route.query.score;
      return score && item.exposureId === "exposure-id-1"
        ? score - 50
        : score && item.exposureId === "exposure-id-2"
        ? 50
        : item.score;
    }
  },

  computed: {}
};
</script>

<style lang="scss" scoped>
.exposure-score {
  width: 65px;
  height: 36px;
  border-radius: 6px;
  font-size: 20px !important;
  user-select: none;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
}

.img-assigned {
  border: 1px solid #8f8f8f;
}

.review-btn {
  font-family: "Open Sans", sans-serif;
}

.status {
  font-size: 12px;
  text-transform: capitalize;
  max-height: 15px !important;
}

.review-icon {
  position: absolute;
  right: -10px;
  top: 5px;
}
</style>
