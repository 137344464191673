<template>
  <div class="">
    <div flat class="pa-4 header" height="96">
      <v-row>
        <v-col cols="4">
          <div class="text-subtitle-2 grey--text">Good afternoon Sarah,</div>
          <div class="text-h6 font-weight-bold">3 claims due for today</div>
        </v-col>
        <v-col cols="5">
          <div class="text-subtitle-2 grey--text">
            Usefull statistics for this week
          </div>
          <div>
            <span class="text-h6 font-weight-bold">22 closed</span
            ><span class="text-subtitle-2 ml-2"
              >20% accepted / 80% rejected</span
            >
          </div>
        </v-col>
        <v-col cols="3">
          <div class="text-subtitle-2 grey--text">Total saved</div>
          <div class="text-h6 font-weight-bold">$ 12,556</div>
        </v-col>
      </v-row>
    </div>

    <div class="table-wrapper">
      <v-container fluid>
        <table class="exposure-table pb-3 pt-6">
          <tbody>
            <tr class="grey--text text-overline">
              <td colspan="7">
                <v-tabs v-model="selection">
                  <v-tab>All</v-tab>
                  <v-tab>New</v-tab>
                  <v-tab>Open</v-tab>
                </v-tabs>
              </td>
              <td></td>
              <td></td>
              <td colspan="3">
                <div class="d-flex">
                  <div>
                    <span class="text-overline grey--text">Assigned to</span>

                    <span class="ml-2 font-weight-medium">Me</span>

                    <v-btn icon>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </div>

                  <v-spacer></v-spacer>
                  <div class="">
                    <span class="text-overline grey--text">Ordered by</span>

                    <span class="ml-2 font-weight-medium">Priority</span>

                    <v-btn icon>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-container>

      <v-container class="" fluid>
        <table class="exposure-table pb-3 pt-6">
          <tbody>
            <tr class="grey--text text-overline">
              <td>claim</td>
              <td>assigned to</td>
              <td>deadline</td>
              <td>at risk</td>
              <td>score</td>
              <td colspan="2">expopures</td>
              <td colspan="2">suspicion</td>
              <td>suggestion</td>
              <td colspan="2">status</td>
            </tr>
          </tbody>
        </table>

        <v-data-iterator :items="claims2" :items-per-page="3">
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.name" cols="12">
                <div class="card mb-5" style="position: relative">
                  <!-- row top section -->

                  <table class="exposure-table row-header">
                    <tbody>
                      <tr>
                        <td colspan="12">
                          <v-divider class="my-2"></v-divider>
                        </td>
                      </tr>

                      <tr @click="rowCick(item)" class="claim-row">
                        <td>{{ item.claimId }}</td>
                        <td>
                          <div style="position: relative">
                            <v-avatar
                              v-for="index in item.assignedCount"
                              :key="index"
                              size="26px"
                              class="img-assigned"
                              :style="{ left: index * 8 + 'px' }"
                            >
                              <img
                                :alt="item.assigned"
                                :src="require('@/assets/img/person.png')"
                              />
                            </v-avatar>
                          </div>
                        </td>
                        <td>{{ item.deadline }}</td>
                        <td>{{ item.riskAmountTotal }}</td>
                        <td>
                          <div class="d-flex align-center justify-center score">
                            <span
                              :class="`${item.riskColor}--text text-uppercase`"
                              >{{ item.riskLevel }}</span
                            >
                          </div>
                        </td>

                        <td colspan="2">
                          <span class="font-weight-black"
                            >{{ item.atRiskCount }} at risk</span
                          ><span class="ml-1"
                            >({{ item.atRiskTotal }} total)</span
                          >
                        </td>
                        <td colspan="2" class="font-weight-black">
                          {{ item.suspicion }}
                        </td>
                        <td></td>
                        <td colspan="2">{{ item.status }}</td>
                      </tr>

                      <tr>
                        <td colspan="12">
                          <v-divider class="my-2"></v-divider>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- row exposure detail sections -->
                  <table
                    class="exposure-table"
                    v-for="(exposure, index) in item.items"
                    :key="index"
                  >
                    <tbody>
                      <tr class="claim-row" @click="rowCick(item)">
                        <td></td>
                        <td class="pl-8">
                          <div style="position: relative">
                            <v-avatar
                              v-for="index in exposure.assignedCount"
                              :key="index"
                              size="26px"
                              class="img-assigned"
                              :style="{ left: index * 8 + 'px' }"
                            >
                              <img
                                :alt="item.assigned"
                                :src="require('@/assets/img/person.png')"
                              />
                            </v-avatar>
                          </div>
                        </td>
                        <td class="pl-8 font-weight-black">
                          {{ exposure.deadline }}
                        </td>
                        <td class="pr-14 text-right">
                          {{ exposure.riskAmount }}
                        </td>
                        <td
                          :class="`${exposure.scoreColor}--text text-right font-weight-bold`"
                        >
                          {{ exposure.score }}
                        </td>
                        <td colspan="4" class="pl-8">
                          {{ exposure.exposureText }}
                        </td>
                        <td>
                          <v-btn small text color="blue">{{
                            exposure.suggestion
                          }}</v-btn>
                        </td>
                        <td colspan="2" class="">
                          <v-btn small color="blue" dark class="status-btn">
                            <div style="position: relative">
                              <div class="btn-text">{{ exposure.status }}</div>
                              <div class="btn-icon">
                                <v-icon>mdi-menu-down</v-icon>
                              </div>
                            </div>
                          </v-btn>
                        </td>
                      </tr>

                      <tr
                        class="row-divider"
                        v-if="index < item.items.length - 1"
                      >
                        <td></td>
                        <td colspan="11">
                          <v-divider class="my-2"></v-divider>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selection: 0,
      expanded: [],
      singleExpand: false,
      rowsPerPageItems: [3, 5, 10, 15],
      headers: [
        {
          text: "Claim",
          align: "start",
          value: "claimId",
        },
        { text: "Assigned to", value: "assigned", align: "start" },
        { text: "Deadline", value: "deadline", align: "start" },
        { text: "At risk", value: "risk", align: "start" },
        { text: "Score", value: "score", align: "start" },
        { text: "Exposures", value: "exposures", align: "start" },
        { text: "Suspicion", value: "suspicion", align: "start" },
        { text: "Suggestion", value: "suggestion", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "", value: "data-table-expand" },
      ],
      claims: [
        {
          id: 1,
          claimId: "0076-87-876",
          assigned: "Ana",
          assignedCount: 1,
          deadline: "12 days",
          riskAmountTotal: "$ 31,900",
          riskLevel: "High",
          riskColor: "red",
          atRiskCount: 1,
          atRiskTotal: 1,
          exposureTotal: "1 at risk (1 total)",
          suspicion: "85%: Staged accident",
          status: "Open",

          items: [
            {
              deadline: "12 days",
              assignedCount: 1,
              riskAmount: "$ 31,900",
              score: 350,
              scoreColor: "red",
              exposureText: "Robin Sparkles - 1st party collision total loss",
              suggestion: "send to siu",
              status: "Not reviewed",
            },
          ],
        },

        {
          id: 2,
          claimId: "0076-99-143",
          assigned: "Swathi",
          assignedCount: 2,
          deadline: "2 days",
          riskAmountTotal: "$ 45,000",
          riskLevel: "High",
          riskColor: "red",
          atRiskCount: 2,
          atRiskTotal: 4,
          exposureTotal: "2 at risk (4 total)",
          suspicion: "90%: Pre-existing injuries",
          status: "New",

          items: [
            {
              deadline: "2 days",
              assignedCount: 1,
              riskAmount: "$ 40,000",
              score: 400,
              scoreColor: "red",
              exposureText: "Kenneth Hargreaves - 1st party accident benefits",
              suggestion: "nudge siu",
              status: "At SIU",
            },

            {
              deadline: "2 days",
              assignedCount: 1,
              riskAmount: "$ 5,000",
              score: 40,
              scoreColor: "green",
              exposureText: "Chris Matherson - 1st party accident benefits",
              suggestion: "accept",
              status: "Back from SIU",
            },
          ],
        },

        {
          id: 3,
          claimId: "0076-77-983",
          assigned: "David",
          assignedCount: 1,
          deadline: "5 days",
          riskAmountTotal: "$ 5,600",
          riskLevel: "High",
          riskColor: "red",
          atRiskCount: 1,
          atRiskTotal: 1,
          exposureTotal: "1 at risk (1 total)",
          suspicion: "75%: Ghost broking",
          status: "Open",
          items: [
            {
              deadline: "5 days",
              assignedCount: 1,
              riskAmount: "$ 5,600",
              score: 200,
              scoreColor: "red",
              exposureText: "Peter Sharpe - 1st party partial theft",
              suggestion: "reject",
              status: "Not reviewed",
            },
          ],
        },
      ],
    };
  },

  computed: {
    claims2() {
      const status = ["All", "New", "Open"][this.selection];

      if (status == "All") return this.claims;

      return this.claims.filter((claim) => claim.status == status);
    },
  },

  methods: {
    rowCick(item) {
      this.$bus.emit("claims-overview-row-click", item);
      this.$router.push("/claim/" + item.claimId);
      this.$store.commit("setClaimId", item.claimId);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
}

.score {
  height: 28px;
  width: 100%;
  border: 1px solid red;
  border-radius: 3px;
  font-weight: 500;
  font-size: 0.95rem;
}

tr.claim-row:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.header {
  border-bottom: 1px solid rgb(232, 234, 234);
}

.exposure-table {
  table-layout: fixed;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

.row-header:hover {
  cursor: pointer;
}

.exposure-table td,
.exposure-table th {
  padding: 0 10px;
}

.flex-item-1 {
  flex-grow: 1;
  background-color: grey;
}

.flex-item-2 {
  flex-grow: 2;
  background-color: green;
}

.flex-item-3 {
  flex-grow: 3;
  background-color: blue;
}

.header {
  background-color: rgb(247, 248, 248);
}

.status {
  width: 155px;
}

.btn-text {
  display: inline-block;
  text-align: left;
  width: 120px;
}

.btn-icon {
  display: inline-block;
  width: 30px;
}

.img-assigned {
  position: absolute;
  top: -12px;
  border: 1px solid #2196f3;
}
</style>