<template>
  <v-row class="ma-0">
    <v-col cols="3" class="py-0">
      <div class="d-flex align-center fill-height">
        <div class="d-flex align-center justify-center score">
          <span :class="`${item.color}--text text-uppercase`">{{
            item.risk
          }}</span>
        </div>
      </div>
    </v-col>

    <v-col cols="6" class="py-0 line-height-small d-flex align-center">
      <div>
        <div class="text-overline grey--text">suspicion</div>
        <div :class="`font-weight-bold ${item.color}--text`">
          {{ item.header }}
        </div>
      </div>
    </v-col>

    <v-col cols="3" class="py-0">
      <div class="d-flex justify-end align-center fill-height">
        <FrissScore />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FrissScore from "@/assets/svg/FrissScore";

export default {
  props: ["item"],

  components: {
    FrissScore,
  },
};
</script>

<style lang="scss" scoped>
.score {
  height: 42px;
  width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.95rem;
}

.line-height-small * {
  line-height: 1.4rem;
}
</style>