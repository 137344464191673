<template>
  <div>
    <div class="landing-header">
      <div class="header-text text-center" style="width: 250px">
        <span class="test2 mr-3">UX Product Design</span>
      </div>
    </div>

    <div class="wrapper">
      <FRISSVideoBackground />
    </div>

    <div class="overlay">
      <div class="box">
        <span class="banner-text">
          The Best Fraud Detection and Risk Assessment Software for P&C
          Insurance
        </span>

        <v-container class="lighten-5 mb-6">
          <v-row class="justify-center">
            <v-col class="col-12 col-lg-4">
              <div class="option" @click="$router.push('/claims')">
                <div>
                  <img
                    alt="friss-logo"
                    height="100px"
                    width="100%"
                    :src="require('@/assets/FRISS_logo.svg')"
                  />
                  <div class="header-text text-center green--text">
                    north America
                  </div>
                  <div class="version text-center">version 1.0</div>
                </div>
              </div>
            </v-col>

            <v-col class="col-12 col-lg-4">
              <div class="option" @click="$router.push('/home/claim/')">
                <div>
                  <img
                    alt="friss-logo"
                    height="100px"
                    width="100%"
                    :src="require('@/assets/FRISS_logo.svg')"
                  />
                  <div class="header-text text-center green--text">
                    north America
                  </div>
                  <div class="version text-center">
                    version {{ config.app_version }}
                  </div>
                </div>
              </div>
            </v-col>

            <v-col class="col-12 col-lg-4">
              <div class="option" @click="GWLogin">
                <div>
                  <img
                    alt="guidewire-logo"
                    height="100px"
                    width="100%"
                    :src="require('@/assets/svg/guidewire.svg')"
                    style="padding: 30px"
                  />
                  <div class="header-text text-center green--text">Demo</div>
                  <div class="version text-center">version 1.0</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/assets/config";
import FRISSVideoBackground from "@/components/FRISSVideoBackground";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("setShowTopNavBar", false);
    });
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit("setShowTopNavBar", true);
    next();
  },

  components: {
    FRISSVideoBackground
  },

  data: () => ({
    config: config
  }),

  methods: {
    go() {
      this.$router.push("/foo");
    },
    GWLogin() {
      window.location.href = "/ClaimCenter.html";
    }
  }
};
</script>

<style lang="scss" scoped>
.header-text {
  color: white;
  background-color: #12111152;
  padding: 0 10px;
}

.version {
  color: rgb(206, 200, 200);
  font-size: 0.7rem;
}

.header-text:hover {
  cursor: pointer;
  background-color: #1f0c729a;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background-color: #0b0d0e80;
}

.option:hover {
  cursor: pointer;
  background-color: #1f0c729a;
}

.wrapper {
  position: absolute;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.landing-header {
  padding: 15px 0 0;
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.25);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-text {
  font-size: 60px;
  font-weight: 900;
  line-height: 1.15;
  color: white;
}

.box {
  max-width: 800px;
}
</style>
