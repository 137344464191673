<template>
  <div class="feedback mt-5">
    <div class="font-weight-bold pb-5">{{ item.text }}</div>
<!--    <div class="pb-6">{{ item.subtext }}</div>-->
    <div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs"
                 v-on="on" class="feedback-icon mr-5" :class="likeButtonClass(attrs)"
          ><v-icon :color="likeIconColor(attrs)">mdi-thumb-up-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in feedbackItems"
            :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon class="feedback-icon"
        ><img alt="dislike button" :src="require('@/assets/svg/dislike.svg')"
      /></v-btn>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },

  data() {
    return {
      feedbackItems: [
        { title: 'Investigation started' },
        { title: 'Suspected fraud' },
        { title: 'Fraud' },
        { title: 'No fraud scoring useful' },
      ]
    }
  },

  methods: {
    likeButtonClass(attrs) {
      return attrs['aria-expanded'] === 'true' ? 'active' : ''
    },
    likeIconColor(attrs) {
      return attrs['aria-expanded'] === 'true' ? '#fff' : '#4d4c4c'
    }
  }
};
</script>

<style lang="scss" scoped>
.feedback-icon {
  width: 94px;
  height: 48px;
  border-radius: 6px;
  background-color: #e9e9e9;
  &.active {
    background-color: #1263B3;
    img {
      color: #fff
    }
  }

}

.v-list {
  background: #4d4c4c;
  min-width: 285px;
}

.v-menu__content.theme--light.menuable__content__active {
  margin-top: 10px
}

.v-list-item:hover {
  background-color: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  border-radius: 6px;
}

</style>
