import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "./store";

import LandingPanel from "@/components/LandingPanel";
import Login from "@/components/Login";

// v1 components
import ClaimPanel from "@/components/ClaimPanel";
import ClaimsOverviewPanel2 from "@/components/ClaimsOverviewPanel2";

// v2 components
import Home from "@/components2/Home";
import ClaimViewer from "@/components2/ClaimViewer";

// v3 components
import Home3 from "@/components3/Home";
import ClaimViewer3 from "@/components3/ClaimViewer";

const routes = [
  //{ path: "/", component: Login, name: "login" },
  { path: "/", component: LandingPanel, name: "landing" },
  //{ path: "/landing", component: LandingPanel, name: "landing" },
  { path: "/claims", component: ClaimsOverviewPanel2, name: "overview" },
  { path: "/claim/:id", component: ClaimPanel, name: "claim-page" },
  { path: "/login", component: Login, name: "login" },
  {
    path: "/home/",
    component: Home,
    name: "home",
    children: [
      {
        path: "claim",
        component: ClaimViewer
      }
    ]
  },
  {
    path: "/home3/",
    component: Home3,
    name: "home3",
    children: [
      {
        path: "claim",
        component: ClaimViewer3
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});


async function guard(to, from, next) {

  if (!store.state.isAuthenticated && to.name != "login") {
    next("/login")
  } else {

    store.commit(
      "setShowTopNavBar",
      ["overview", "claim-page"].includes(to.name)
    );

    next()
  }
}

//router.beforeEach(guard);

Vue.use(VueRouter);

export default router;
