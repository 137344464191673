<template>
  <v-card flat class="my-6 mx-9 rounded-lg" color="#f5f7f7">
    <div class="network-wrapper" :style="style">
      <v-btn
        class="network-control-fit"
        x-small
        @click="fit()"
        color="#616161"
        dark
        >fit</v-btn
      >
      <div id="network-demo" class="mt-2"></div>
    </div>
  </v-card>
</template>

<script>
import ForceGraph from "force-graph";
import config from "@/assets/config";

export default {
  props: ["show", "sizePx", "sizePy"],

  mounted() {
    this.createNetwork();
  },

  data() {
    return {
      intialized: false,
      details: {
        height: 420,
        network: {
          nodes: [
            {
              id: 1,
              node_type: "person",
              label: "Meg",
              outerCircle: "#2196F3"
            },
            {
              id: 2,
              node_type: "claim",
              label: "this claim"
            },

            {
              id: 3,
              node_type: "person",
              label: "brad"
            },

            {
              id: 4,
              node_type: "vehicle",
              label: "Hummer"
            },

            {
              id: 5,
              node_type: "vehicle",
              label: "Honda Accord"
            },

            {
              id: 6,
              node_type: "nicb",
              label: "NICB 6 Forewarn"
            },

            {
              id: 7,
              node_type: "person",
              label: "Chris"
            },

            {
              id: 8,
              node_type: "person",
              label: "Sam"
            },

            {
              id: 9,
              node_type: "list_item",
              label: "watch list"
            },

            {
              id: 10,
              node_type: "FullAddress",
              label: "address"
            },

            {
              id: 11,
              node_type: "ContactMobile",
              label: "phone"
            },

            {
              id: 12,
              node_type: "person",
              label: "fraudster x",
              outerCircle: "#FF1744"
            },

            {
              id: 13,
              node_type: "FullAddress",
              label: "business address"
            },

            {
              id: 14,
              node_type: "person",
              label: "fraudster y",
              outerCircle: "#FF1744"
            },

            {
              id: 15,
              node_type: "policy",
              label: "policy"
            },

            {
              id: 16,
              node_type: "note",
              label: "exposure"
            },

            {
              id: 17,
              node_type: "note",
              label: "exposure"
            },

            {
              id: 18,
              node_type: "note",
              label: "exposure"
            },

            {
              id: 19,
              node_type: "note",
              label: "exposure"
            },

            {
              id: 20,
              node_type: "screening",
              label: "screening"
            }
          ],

          links: [
            { source: 1, target: 2, label: ["driver"] },
            { source: 1, target: 6, label: ["has"] },
            { source: 1, target: 15, label: ["policy holder"] },
            { source: 2, target: 3, label: ["driver", "3rd party"] },
            { source: 2, target: 7, label: ["passenger", "3rd party"] },
            { source: 2, target: 8, label: ["passenger", "3rd party"] },
            { source: 2, target: 15, label: ["claim_policy"] },
            { source: 1, target: 4, label: ["drives"] },
            { source: 3, target: 5, label: ["drives"] },
            { source: 4, target: 5, label: ["crash"] },
            { source: 7, target: 12, label: ["connected in", "2 steps"] },
            { source: 8, target: 11, label: ["has"] },
            { source: 11, target: 7, label: ["has"] },
            { source: 8, target: 10, label: ["lives"] },
            { source: 10, target: 1, label: ["lives"] },
            { source: 8, target: 13, label: ["works"] },
            { source: 8, target: 14, label: ["connected in", "1 step"] },
            { source: 8, target: 9, label: ["on"] },
            { source: 1, target: 16, label: ["10k", "Broken leg"] },
            { source: 7, target: 17, label: ["50k", "Whiplash"] },
            { source: 8, target: 18, label: ["10k", "Broken nose"] },
            { source: 3, target: 19, label: ["5k", "Car damages"] },
            { source: 2, target: 20, label: ["screening"] }
          ]
        }
      }
    };
  },

  computed: {
    network() {
      return this.details.network;
    },

    style() {
      return `width: ${this.sizePx}; height: {${this.sizePy}}`;
    }
  },

  methods: {
    createNetwork() {
      const el = document.getElementById("network-demo");

      this.graph = ForceGraph()(el);

      this.graph
        .height(700)
        .width(1200)
        .nodeCanvasObjectMode(() => "after")
        .linkCanvasObject((link, ctx) => {
          if (link.label) {
            this.drawEdgeLabel(link, ctx);
          }
        })
        .linkCanvasObjectMode(() => "after")
        .linkDirectionalParticles(1);

      this.graph.nodeCanvasObject((node, ctx, globalScale) => {
        // outer circle
        if (node.outerCircle) {
          ctx.beginPath();
          ctx.arc(node.x, node.y, 10, 0, 2 * Math.PI, false);
          ctx.strokeStyle = node.outerCircle;
          ctx.lineWidth = 1.5 / globalScale;
          ctx.stroke();
        }

        // icon
        ctx.font = "3px FontAwesome";
        ctx.fillStyle = "white";
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        ctx.fillText(node.icon_code, node.x, node.y);

        // label
        ctx.font = `3px Sans-Serif`;
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(node.label, node.x, node.y + 7);
      });

      // style nodes
      this.setIconStyles(this.network.nodes);

      // set data
      this.graph.graphData(this.network);

      setTimeout(this.fit, 500);
    },

    fit() {
      this.graph.zoomToFit(250, 100);
    },

    drawEdgeLabel(link, ctx) {
      if (!link.label) return;

      const lamda = 0.5;
      const x = lamda * link.source.x + (1 - lamda) * link.target.x;
      const y = lamda * link.source.y + (1 - lamda) * link.target.y;

      const offset = 0;
      const line_height = 4;

      const labelFont = `3px open sans`;
      const labelColor = "#757575";

      ctx.font = labelFont;
      ctx.fillStyle = labelColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      for (let i = 0; i < link.label.length; i++) {
        ctx.fillText(link.label[i], x, y + offset + i * line_height);
      }
    },

    setIconStyles(nodes) {
      const node_styles = config.node_styles;

      nodes.forEach((d) => {
        const node_style = node_styles[d.node_type];

        if (node_style) {
          d.color = node_style.color;
          d.icon_code = node_style.icon_code;
          d.icon_color = node_style.icon_color;
        } else {
          d.color = node_styles["default"].color;
          d.icon_code = node_styles["default"].icon_code;
          d.icon_color = node_styles["default"].icon_color;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.network-wrapper {
  position: relative;
}

.network-control-fit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
</style>