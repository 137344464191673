<template>
  <div class="card-with-footer">
    <div class="top pa-2 d-flex" :style="`background-color: ${colorTop}`">
      <v-container>
        <v-row no-gutters>
          <slot name="top">Top content</slot>
        </v-row>
      </v-container>
    </div>
    <div class="bottom pa-2 d-flex" :style="`background-color: ${colorBottom}`">
      <v-container>
        <v-row no-gutters>
          <slot name="bottom">Bottom content</slot>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorTop: {
      type: String,
      required: true
    },
    colorBottom: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.top {
  position: relative;
  border-radius: 6px;
  z-index: 2;
}
.bottom {
  position: relative;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 6px;
  margin-top: -6px;
  z-index: 1;
}
</style>
