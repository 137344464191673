import Vue from "vue";
import Vuex from "vuex";
import { claims } from "@/assets/data/claims_demo";
import { claims2 } from "@/assets/data/claims_demo2";
import router from "@/router";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    claimId: null,
    claims: claims,
    claims2: claims2,
    isIframe: false,
    showTopNavBar: false,
    isAuthenticated: true
  },
  mutations: {

    setClaimId(state, value) {
      state.claimId = value;
    },

    setShowTopNavBar(state, value) {
      state.showTopNavBar = value;
    },

    setIsIframe(state, value) {
      value ? (state.isIframe = true) : (state.isIframe = false);
    },

    check(state, val) {

      // if (val == 'YXNrcGV0ZXJpZnlvdWNhbnNob3d0aGlzYWxyZWFkeQ==') {
      //   state.isAuthenticated = true;
      //   router.push('/landing')
      // }
    }
  },

  getters: {
    getClaimById: (state) => (id) => {
      return state.claims.filter((d) => d.header.claimId === id)[0];
    }
  }
});
