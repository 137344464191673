<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.8301 2.96356C3.49972 2.81318 4 2.21501 4 1.5C4 0.671573 3.32843 0 2.5 0C1.67157 0 1 0.671573 1 1.5C1 2.08937 1.33991 2.59935 1.83439 2.8446L1.41137 5.00258C0.624197 5.04844 0 5.70133 0 6.5C0 7.32843 0.671573 8 1.5 8C1.73683 8 1.96084 7.94511 2.16001 7.84737L4.02973 9.20137C4.01023 9.29788 4 9.39774 4 9.5C4 10.3284 4.67157 11 5.5 11C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8C5.13818 8 4.80629 8.1281 4.54719 8.34143L2.86485 7.12312C2.95163 6.93334 3 6.72232 3 6.5C3 6.41903 2.99359 6.33957 2.98124 6.26208L6.66644 3.74725C6.90483 3.90689 7.19154 4 7.5 4C8.32843 4 9 3.32843 9 2.5C9 1.67157 8.32843 1 7.5 1C6.67157 1 6 1.67157 6 2.5C6 2.65512 6.02355 2.80474 6.06726 2.94549L2.49865 5.38074C2.45944 5.34573 2.41838 5.31275 2.37563 5.28197L2.8301 2.96356ZM2.5 2C2.77614 2 3 1.77614 3 1.5C3 1.22386 2.77614 1 2.5 1C2.22386 1 2 1.22386 2 1.5C2 1.77614 2.22386 2 2.5 2ZM7.5 3C7.77614 3 8 2.77614 8 2.5C8 2.22386 7.77614 2 7.5 2C7.22386 2 7 2.22386 7 2.5C7 2.77614 7.22386 3 7.5 3ZM1.5 7C1.77614 7 2 6.77614 2 6.5C2 6.22386 1.77614 6 1.5 6C1.22386 6 1 6.22386 1 6.5C1 6.77614 1.22386 7 1.5 7ZM6 9.5C6 9.77614 5.77614 10 5.5 10C5.22386 10 5 9.77614 5 9.5C5 9.22386 5.22386 9 5.5 9C5.77614 9 6 9.22386 6 9.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      default: "#4d4c4c",
      type: String
    },
    height: {
      default: 11,
      type: [Number, String]
    },
    width: {
      default: 9,
      type: [Number, String]
    }
  }
};
</script>
