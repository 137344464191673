<template>
  <v-row class="pa-1">
    <v-col cols="7">
      <v-row>
        <v-col cols="5">
          <div class="font-weight-bold font-16 font-color-dark mb-5">
            Vehicle details
          </div>
          <div>
            <img :src="vehicle.image" class="vehicle-img" />
          </div>
        </v-col>
        <v-col cols="7" class="mt-10">
          <div class="pb-5">
            <div class="font-12 font-color-light">Brand &amp; make</div>
            <div class="font-weight-bold font-color-dark">
              {{ vehicle.brand }}
            </div>
          </div>
          <div class="pb-5">
            <div class="font-12 font-color-light">VIN</div>
            <div class="font-weight-bold font-color-dark">
              {{ vehicle.vin }}
            </div>
          </div>
          <div class="pb-5">
            <div class="font-12 font-color-light">Year</div>
            <div class="font-weight-bold font-color-dark">
              {{ vehicle.year }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="5">
      <!-- <v-img max-width="100%" :src="vehicle.overview" /> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["vehicle"]
};
</script>

<style>
.vehicle-img {
  border-radius: 50%;
  width: 148px;
  height: 148px;
  object-fit: cover;
}
</style>
