<template>
  <div class="d-flex flex-column" style="height: 100%">
    <div class="d-flex align-center justify-space-between pa-5">
      <span>Discussion</span>
      <v-btn icon>
        <v-icon @click="closeDrawer">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider />
    <v-spacer />
    <div class="pa-5">
      <div class="pa-1 mb-6">
        <div v-for="(message, index) in items" :key="index" class="mb-4">
          <div class="font-color-light font-12">
            {{ message.name }} - {{ message.time }}
          </div>
          <div class="font-color-dark">
            {{ message.text }}
          </div>
        </div>
      </div>

      <v-text-field
        v-model="currentMessage"
        :append-icon="'mdi-send'"
        filled
        label="Type your message"
        type="text"
        @click:append="submitComment()"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";

export default {
  data() {
    return {
      currentMessage: null,
      items: [
        {
          name: "Micah Jerwold",
          time: "Yesterday",
          text: "This looks a lot like that one case from last year where we discovered fraud."
        },
        {
          name: "You",
          time: "31 minutes ago",
          text: "Yes, based on the indicators something is going on here."
        }
      ]
    };
  },

  methods: {
    closeDrawer() {
      eventBus.$emit("drawerRight");
    },
    submitComment() {
      this.items.push({
        name: "You",
        time: "Now",
        text: this.currentMessage
      });
      this.currentMessage = null;
    }
  }
};
</script>

<style scoped></style>
