<template>
  <v-row class="pa-1">
    <v-col cols="12">
      <div class="font-12 font-color-blue">What to ask for next?</div>
      <div class="d-flex font-weight-bold font-color-dark">
        <div v-for="(askItem, index) in party.askFor" :key="index">
          {{ askItem
          }}<span v-if="index + 1 !== party.askFor.length" class="pr-2">,</span>
        </div>
      </div>
      <div></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["party"]
};
</script>

<style></style>
