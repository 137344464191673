<template>
  <div class="top-header">
    <v-app-bar app v-if="show" color="white" flat>
      <router-link to="/">
        <div class="d-flex align-center logo">
          <img alt="FRISS Logo" src="@/assets/FRISS_logo.svg" height="55px" />
        </div>
      </router-link>

      <div class="mt-1 ml-2">
        <v-tooltip bottom transition="fade-transition" :open-delay="750">
          <template v-slot:activator="{ on, attrs }">
            <span
              @click="$router.push('/claims')"
              class="px-2 py-1 link"
              v-bind="attrs"
              v-on="on"
              >Claims</span
            >
          </template>
          <span>Go back to the overview page</span>
        </v-tooltip>

        <span v-show="routeName == 'claim-page'">
          /
          <span class="font-weight-black ml-1">{{ claimId }}</span>
        </span>
      </div>

      <v-spacer></v-spacer>

      <div class="top-nav-btn">
        <v-icon>mdi-magnify</v-icon>
      </div>

      <div class="top-nav-btn">
        <v-icon>mdi-bell</v-icon>
      </div>

      <div class="top-nav-btn">
        <v-icon>mdi-cog</v-icon>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    show() {
      return this.$store.state.showTopNavBar;
    },

    claimId() {
      return this.$store.state.claimId;
    },

    routeName() {
      return this.$route.name;
    }
  },

  methods: {
    go(item) {
      this.$router.push(item.to);
    }
  }
};
</script>

<style lang="scss" scoped>
.claim-id:hover {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.top-nav-btn {
  border: 1px solid rgb(224, 221, 221);
  padding: 8px;
  margin: 0 4px;
  border-radius: 4px;
}

.top-nav-btn:hover {
  border: 1px solid rgb(151, 147, 147);
  cursor: pointer;
}

.top-header {
  border-bottom: 1px solid rgba(190, 194, 194, 0.25);
}
</style>
