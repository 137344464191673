<template>
  <v-expansion-panels multiple hover class="mt-0 exposure font-color-dark">
    <v-expansion-panel
      v-for="(exposure, index) in exposures"
      :key="index"
      :readonly="!hasItems(exposure.items)"
    >
      <v-expansion-panel-header
        :expand-icon="expandIcon(exposure.items)"
        class="px-0 py-5"
      >
        <div>
          <span class="score font-weight-bold pr-4">{{ exposure.score }}</span
          ><span class="font-weight-bold">{{ exposure.title }}</span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="hasItems(exposure.items)" class="pr-0">
        <v-expansion-panel
          v-for="(item, index) in exposure.items"
          :key="index"
          :readonly="readonlyVisualizer(item.visualizer)"
        >
          <v-expansion-panel-header
            :expand-icon="expandIcon(item.visualizer)"
            class="px-0 py-5"
          >
            <div>
              <span class="score pr-4">{{ item.score }}</span
              ><span>{{ item.title }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <component
                :is="item.visualizer"
                :items="item.details"
              ></component>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import TextVisualizer from "@/components3/visualizers/TextVisualizer";
import ListVisualizer from "@/components3/visualizers/ListVisualizer";

export default {
  components: {
    TextVisualizer
  },

  methods: {
    hasItems(list) {
      return list && list.length;
    },
    readonlyVisualizer(visualizer) {
      return !visualizer;
    },
    expandIcon(visualizer) {
      return !visualizer ? "" : "$expand";
    }
  },

  data() {
    return {
      exposures: [
        {
          title: "General claim model",
          score: 180,
          items: [
            {
              score: 100,
              visualizer: TextVisualizer,
              title:
                "claim report timings checks - Claim ocurred within 14 days of the inceptiondate of the policy.",
              details: [
                {
                  title: "Keywords in the Claim description",
                  text: "El asegurado se desvió para   evitar   golpear a un animal y chocó contra un poste de luz."
                },
                {
                  title: "Keywords in the Claim notes",
                  text: "El asegurado se desvió para evitar golpear a un animal y chocó contra un poste de luz. || 2021-07-14 EXPOSURE FRISS SCORE GREEN investigation Score: 0 SUBSCORE FOR 000-00-000190 public || 2021-07-14 EXPOSURE FRISS SCORE GREEN investigation Score: 0 SUBSCORE FOR 000-00-000190 public || 2021-07-14 CLAIM FRISS SCORE RED, BLOCKING PAYMENT investigation Score: 230 RED public || 2021-07-14 general Unable to create reserve for CostType = Claim Cost, CostCategory = Auto body, Amount = 2500(USD) because financial holds are in place."
                }
              ]
            },
            {
              score: 50,
              title:
                "textmining claim statement - Keywords existing in the claim statement. High scores."
            },
            {
              score: 30,
              title:
                "claim during the night - Claim occurred between 00:00 and 06:00."
            }
          ]
        },
        {
          title: "Category: Object",
          score: 100,
          items: [
            {
              score: 100,
              visualizer: ListVisualizer,
              title: "modelo de fraude IA - Alto",
              details: {
                title: "AI fraud model top 5 risk factors",
                list: [
                  {
                    title:
                      "El titular de la póliza tuvo varios siniestros dentro de un año con un nombre diferente.",
                    percentage: 87
                  },
                  {
                    title:
                      "Presuntamente, el conductor estaba evitando una colisión.",
                    percentage: 54
                  },
                  {
                    title: "Hubo una lesión sin asistencia.",
                    percentage: 42
                  },
                  {
                    title: "El siniestro se produjo en horas de la noche.",
                    percentage: 39
                  },
                  {
                    title:
                      "El siniestro se notificó poco después del inicio de la póliza.",
                    percentage: 15
                  }
                ]
              }
            }
          ]
        },
        {
          title: "Category: Morality",
          score: 50,
          items: [{ score: 50, title: "Medio" }]
        },
        {
          title: "Driver model",
          score: 1,
          items: [
            {
              score: 1,
              title:
                "[role uniqueness check] - Driver is also involved in a different role in the screening (non-unique)."
            }
          ]
        },
        {
          title: "Data sources without indicator hits",
          score: 0
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.exposure {
  & * {
    color: #4d4c4c;
  }

  .score {
    display: inline-block;
    min-width: 50px;
    text-align: right;
  }

  .v-expansion-panel::before {
    box-shadow: none;
  }

  .v-expansion-panel--active:not(:first-child)::after,
  .v-expansion-panel--active + .v-expansion-panel::after {
    opacity: 1;
  }
}

.v-expansion-panel-content__wrap {
  padding-right: 0;
}
</style>
