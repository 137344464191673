<template>
  <v-card color="white" height="510" flat>
    <v-container class="mt-4">
      <v-row class="pa-5 col-12">
        <Diagnostics />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Diagnostics from "@/components3/diagnostics/Diagnostics.vue";

export default {
  components: {
    Diagnostics
  }
};
</script>

<style lang="scss" scoped></style>
