<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="pa-5 ml-6 mb-5 mr-8 text-visualizer rounded-sm"
    >
      <div class="pb-5 font-weight-bold">{{ item.title }}</div>
      <div>{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      Array
    }
  }
};
</script>

<style lang="scss" scoped>
.text-visualizer {
  background-color: #f5f7f7;
  border-radius: 6px;
}
</style>
